const pospListFormatter = (data, dataKey) => {

  const dataSet = {};
  dataSet[dataKey] = JSON.parse(JSON.stringify(data));
  console.log(data, "data in formatter")
  if (dataSet[dataKey] && dataSet[dataKey]["items"]) {
    dataSet[dataKey]["items"].forEach((element) => {
      element["rm_name"] = element?.rm?.name
      element["total_active_renewal"] = 0
      element["pending"]=0
      element["done"]=0
      element["failed"]=0
      element["missed"]=0
      element.renewal_posp?.forEach((item)=>{
        element["total_active_renewal"] += 1
        if(item.status==="DUE"){
          element["pending"]+=1
        }
        if(item.status==="DONE"){
          element["done"]+=1
        }
        if(item.status==="MISSED"){
          element["missed"]+=1
        }
        if(item.status==="FAILED"){
          element["failed"]+=1
        }
      })
    });
  }
  console.log(dataSet, "dataset to return from formatter")
  return dataSet;
};

  export default pospListFormatter;
  