// @ts-nocheck

import { useEffect, useState } from "react";
import { FormWidget } from "lib";
import { MenuWidget } from "./MenuWidget";

import { useEmployeeDetailQuery } from "store/apiSlice";

import { useEmployeeListAutocompleteQuery } from "store/apiSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "helperFn/errorSerializer";

import { useEmployeeUpdateMutation } from "store/apiSlice";
import { default as employeeUpdateFormatter } from "transformations/employeeUpdateFormatter";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setStore } from "store/appSlice";
import { default as employeeDetailFormatter } from "transformations/employeeDetailFormatter";
import { default as employeeListAutocompleteFormatter } from "transformations/employeeListAutocompleteFormatter";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
function EmployeeUpdate() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const [employeeDetailSkip, setemployeeDetailSkip] = useState(true);
    const [employeeDetailParams, setemployeeDetailParams] = useState({});
    const employeeDetailQuery = useEmployeeDetailQuery(employeeDetailParams, { skip: employeeDetailSkip });
    const [employeeListAutocompleteSkip, setemployeeListAutocompleteSkip] = useState(true);
    const [employeeListAutocompleteParams, setemployeeListAutocompleteParams] = useState({});
    const employeeListAutocompleteQuery = useEmployeeListAutocompleteQuery(employeeListAutocompleteParams, {
        skip: employeeListAutocompleteSkip,
    });
    const { enqueueSnackbar } = useSnackbar();
    const [employeeUpdateMutationTrigger, employeeUpdateMutationResult] = useEmployeeUpdateMutation();
    const history = useNavigate();
    const dispatch = useDispatch();
    const [listOfEmployee, setlistOfEmployee] = useState([]);
    const employeeDetailData = useSelector((states) => states?.appStore?.employeeDetailData);
    ({ id: page.parameters.route.id } = useParams());
    dispatch(setStore({ currentPageTitle: "" }));

    const leadNavigate = (data) => {
        history("/employee-list");
    };

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Employee Updated Successfully.", { variant: "success" });
    };

    const employeeUpdate = async (data) => {
        var apiData = employeeUpdateFormatter(data);

        if (apiData) {
            employeeUpdateMutationTrigger({
                id: apiData["id"],
                data: {
                    name: apiData["name"],
                    phone: apiData["phone"],
                    email: apiData["email"],
                    address: apiData["address"],
                    city: apiData["city"],
                    state: apiData["state"],
                    pincode: apiData["pincode"],
                    bank_account_name: apiData["bank_account_name"],
                    bank_account_no: apiData["bank_account_no"],
                    bank_ifsc_code: apiData["bank_ifsc_code"],
                    bank_name: apiData["bank_name"],
                    aadhar: apiData["aadhar"],
                    pan: apiData["pan"],
                    role: apiData["role"],
                    department: apiData["department"],
                    status: apiData["status"],
                    reporting_manager_id: apiData["reporting_manager_id"],
                },
            })
                .unwrap()
                .then((data) => {
                    showSuccessAlert(data);
                    leadNavigate(data);
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(errorSerializer(error), { variant: "error" });
                });
        }
    };

    const employeeUpdateApi = (data) => {
        employeeUpdate(data);
    };

    const employeeList = (data) => {
        const responseData = data?.data || data;
        setlistOfEmployee(employeeListAutocompleteFormatter(responseData));
    };

    const employeeListAutocompleteSuccess = (data) => {
        employeeList(data);
    };
    useEffect(() => {
        if (employeeListAutocompleteQuery.isSuccess) {
            employeeListAutocompleteSuccess(employeeListAutocompleteQuery);
        } else if (employeeListAutocompleteQuery.isError) {
            employeeListAutocompleteSuccess(null);
        }
    }, [employeeListAutocompleteQuery]);
    const employeeListAutocomplete = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setemployeeListAutocompleteSkip(false);
    };

    useEffect(() => {
        employeeListAutocomplete();
    }, []);

    const employeeDetailDataData = (data) => {
        var formatedData = {
            ...employeeDetailFormatter(data?.data || data, "employeeDetailData"),
        };
        dispatch(setStore(formatedData));
    };

    const employeeDetailSuccess = (data) => {
        employeeDetailDataData(data);
    };
    useEffect(() => {
        if (employeeDetailQuery.isSuccess) {
            employeeDetailSuccess(employeeDetailQuery);
        } else if (employeeDetailQuery.isError) {
            employeeDetailSuccess(null);
        }
    }, [employeeDetailQuery]);
    const employeeDetail = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setemployeeDetailParams({ id: id });
        setemployeeDetailSkip(false);
    };

    useEffect(() => {
        employeeDetail();
    }, [id]);

    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "Update Employee" }}>
                <FormWidget
                    sx={{ my: 2 }}
                    header="Update Employee"
                    submitButtonLabel="Update Employee"
                    defaultValues="employeeDetailData"
                    Onsubmit={employeeUpdateApi}
                    fieldsets={[
                        {
                            direction: "row",
                            fields: [
                                { label: "Employee Name", name: "name", type: "text", required: true },
                                { label: "Phone", name: "phone", type: "phone", required: true },
                                { label: "Email", name: "email", type: "email", required: true },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Address Details",
                            fields: [
                                { label: "Address", name: "address", type: "text" },
                                { label: "City", name: "city", type: "text" },
                                { label: "State", name: "state", type: "text" },
                                {
                                    label: "Pincode",
                                    name: "pincode",
                                    type: "text",
                                    validation: { minLength: 6, maxLength: 6, pattern: "^\\d+$" },
                                },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Account Details",
                            fields: [
                                { label: "A/C User name", name: "bank_account_name", type: "text" },
                                { label: "Bank Name", name: "bank_name", type: "text" },
                                {
                                    label: "A/C No.",
                                    name: "bank_account_no",
                                    type: "text",
                                    validation: { minLength: 10, maxLength: 15, pattern: "^\\d+$" },
                                },
                                {
                                    label: "IFSC Code",
                                    name: "bank_ifsc_code",
                                    type: "text",
                                    validation: {
                                        minLength: 11,
                                        maxLength: 11,
                                        pattern: "^[A-Za-z]{4}[0]{1}[0-9]{6}$",
                                    },
                                },
                            ],
                        },
                        {
                            direction: "row",
                            header: "KYC Details",
                            fields: [
                                {
                                    label: "Aadhaar No",
                                    name: "aadhar",
                                    type: "text",
                                    validation: { minLength: 12, maxLength: 12, pattern: "^[1-9]{1}[0-9]{11}$" },
                                },
                                {
                                    label: "Pan No",
                                    name: "pan",
                                    type: "text",
                                    validation: {
                                        minLength: 10,
                                        maxLength: 10,
                                        pattern: "^[A-Za-z]{3}[CPHFATBLJGcphfatbljg]{1}[A-Za-z]{1}[0-9]{4}[A-Za-z]{1}$",
                                    },
                                },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Department Details",
                            fields: [
                                {
                                    label: "Role",
                                    name: "role",
                                    type: "select",
                                    options: [
                                        { id: "CXO", label: "CXO" },
                                        { id: "RM", label: "RM" },
                                    ],
                                    required: true,
                                },
                                {
                                    label: "Department",
                                    name: "department",
                                    type: "select",
                                    options: [
                                        { id: "OPERATIONS", label: "Operations" },
                                        { id: "SALES", label: "Sales" },
                                        { id: "BUSINESS", label: "Business" },
                                    ],
                                    required: true,
                                },
                                {
                                    label: "Reporting Manager",
                                    name: "manager",
                                    type: "select",
                                    options: listOfEmployee,
                                },
                                { label: "Is Active", name: "status", type: "checkbox" },
                            ],
                        },
                    ]}
                ></FormWidget>
            </MenuWidget>
        </div>
    );
}
export default EmployeeUpdate;
