import { PMMenu } from "lib";

import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
export interface MenuWidgetProps {
    data?: Array<Object>;
    datamapper?: Object;
    onClick?: Function;
    children?: ReactNode;
}
export function MenuWidget(props: MenuWidgetProps) {
    const history = useNavigate();

    const MenuHomeNavigate = (data) => {
        history("" + "" + data["url"]);
    };

    const MenuHomeCLick = (data) => {
        MenuHomeNavigate(data);
    };

    const createComponent = (data, index) => {
        return (
            <PMMenu
                open={true}
                title={
                    data
                        ? props.datamapper && props.datamapper["title"]
                            ? data[props.datamapper["title"]]
                            : data?.title
                        : ""
                }
                currentUrl={
                    data
                        ? props.datamapper && props.datamapper["currentUrl"]
                            ? data[props.datamapper["currentUrl"]]
                            : data?.currentUrl
                        : ""
                }
                logo="https://www.permutehq.com/assets/images/logo/logo.svg"
                onClick={MenuHomeCLick}
                menuItems={[
                    { type: "Subheader", text: "Renewals" },
                    { type: "Divider" },
                    {
                        type: "Nested",
                        text: "Policy Renewals",
                        icon: { icon: "receipt_long", text_color: "info", size: "large" },
                        collapseItem: [
                            {
                                type: "Collapse",
                                text: "Dashboard",
                                url: "/policy-renewals-dashboard",
                                icon: { icon: "dashboard", text_color: "info", size: "large" },
                            },
                            {
                                type: "Collapse",
                                text: "Pending",
                                url: "/policy-renewals-pending",
                                icon: { icon: "pending_actions", text_color: "info", size: "large" },
                            },
                            {
                                type: "Collapse",
                                text: "Missed",
                                url: "/policy-renewals-missed",
                                icon: { icon: "playlist_remove", text_color: "info", size: "large" },
                            },
                            {
                                type: "Collapse",
                                text: "Done",
                                url: "/policy-renewals-done",
                                icon: { icon: "task", text_color: "info", size: "large" },
                            },
                            {
                                type: "Collapse",
                                text: "All",
                                url: "/policy-renewals-all",
                                icon: { icon: "summarize", text_color: "info", size: "large" },
                            },
                        ],
                    },
                    { type: "Divider" },
                    {
                        type: "Nested",
                        text: "POSP Renewals",
                        icon: { icon: "switch_account", text_color: "info", size: "large" },
                        collapseItem: [
                            {
                                type: "Collapse",
                                text: "Dashboard",
                                url: "/posp-renewals-dashboard",
                                icon: { icon: "dashboard", text_color: "info", size: "large" },
                            },
                            {
                                type: "Collapse",
                                text: "List",
                                url: "/posp-renewals-list",
                                icon: { icon: "ballot", text_color: "info", size: "large" },
                            },
                        ],
                    },
                    { type: "Divider" },
                    {
                        type: "Nested",
                        text: "My Activities",
                        icon: { icon: "list_alt", text_color: "info", size: "large" },
                        collapseItem: [
                            {
                                type: "Collapse",
                                text: "Follow-ups",
                                url: "/my-followup",
                                icon: { icon: "edit_document", text_color: "info", size: "large" },
                            },
                        ],
                    },
                    { type: "Divider" },
                    {
                        type: "Nested",
                        text: "New Policy",
                        icon: { icon: "insert_drive_file", text_color: "info", size: "large" },
                        collapseItem: [
                            {
                                type: "Collapse",
                                text: "Create Policy",
                                url: "/policy-create",
                                icon: { icon: "note_add", text_color: "info", size: "large" },
                            },
                            {
                                type: "Collapse",
                                text: "View Policy",
                                url: "/policy-list",
                                icon: { icon: "note_alt", text_color: "info", size: "large" },
                            },
                        ],
                    },
                    { type: "Divider" },
                    {
                        type: "Nested",
                        text: "Extract Policy",
                        icon: { icon: "insert_drive_file", text_color: "info", size: "large" },
                        collapseItem: [
                            {
                                type: "Collapse",
                                text: "Upload Pdf",
                                url: "/upload-pdf",
                                icon: { icon: "note_add", text_color: "info", size: "large" },
                            },
                            {
                                type: "Collapse",
                                text: "View Extracted Policy",
                                url: "/parsed-policy-list",
                                icon: { icon: "note_alt", text_color: "info", size: "large" },
                            },
                        ],
                    },
                    { type: "Divider" },
                    { text: "KYC", url: "/kyc", icon: { icon: "sensor_occupied", text_color: "info", size: "large" } },
                    { type: "Divider" },
                    {
                        text: "POS Training",
                        url: "/posp-training",
                        icon: { icon: "on_device_training", text_color: "info", size: "large" },
                    },
                    { type: "Divider" },
                    {
                        text: "POSP Admin",
                        url: "/posp-admin",
                        icon: { icon: "admin_panel_settings_icon", text_color: "info", size: "large" },
                    },
                    { type: "Divider" },
                    {
                        text: "Leads",
                        url: "/leads",
                        icon: { icon: "connect_without_contact_icon", text_color: "info", size: "large" },
                    },
                    { type: "Divider" },
                    {
                        text: "Payout Admin",
                        url: "/payout-admin",
                        icon: { icon: "paid", text_color: "info", size: "large" },
                    },
                    { type: "Divider" },
                    {
                        text: "Payin Admin",
                        url: "/payin-admin",
                        icon: { icon: "payments", text_color: "info", size: "large" },
                    },
                    { type: "Divider" },
                    {
                        type: "Nested",
                        text: "Employee",
                        url: "/payin-admin",
                        icon: { icon: "people_alt", text_color: "info", size: "large" },
                        collapseItem: [
                            {
                                type: "Collapse",
                                text: "Create Employee",
                                url: "/employee-create",
                                icon: { icon: "person_add", text_color: "info", size: "large" },
                            },
                            {
                                type: "Collapse",
                                text: "Employee List",
                                url: "/employee-list",
                                icon: { icon: "people_alt", text_color: "info", size: "large" },
                            },
                        ],
                    },
                    { type: "Divider" },
                    {
                        text: "Reports",
                        url: "/reports",
                        icon: { icon: "analytics", text_color: "info", size: "large" },
                    },
                    { type: "Divider" },
                    {
                        text: "Helpdesk",
                        url: "/helpdesk",
                        icon: { icon: "help_center", text_color: "info", size: "large" },
                    },
                    { type: "Divider" },
                ]}
            >
                {props.children}
            </PMMenu>
        );
    };

    return (
        <>
            {Array.isArray(props.data)
                ? props.data?.map((ele, index) => {
                      return createComponent(ele, index);
                  })
                : props.data
                ? createComponent(props.data, "1")
                : createComponent([], "1")}
        </>
    );
}
export default MenuWidget;
