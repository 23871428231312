const transformPolicyDoneStatsCard = (data) => {
  const dataSet = JSON.parse(JSON.stringify(data?.items||data));
  const dataset = {'intervalDone':[], 'productWiseDone':[], 'totalPremiumDone':[]}
  console.log(dataSet, '==============================')
  dataset.intervalDone.push({'label':'Total Renewals Done','value':dataSet['total_done_renewal']})
  dataset.intervalDone.push({'label':'This Month','value':dataSet['total_done_renewal_this_month']})
  dataset.intervalDone.push({'label':'Last Month','value':dataSet['total_done_renewal_last_month']})
  dataset.totalPremiumDone.push({'label':'Total Premium Done','value':dataSet['total_premium_done'], type:'currency'})
  dataset.totalPremiumDone.push({'label':'This Month','value':dataSet['total_premium_done_this_month'], type:'currency'})
  dataset.totalPremiumDone.push({'label':'Last Month','value':dataSet['total_premium_done_last_month'], type:'currency'})
  dataset.productWiseDone.push({'label':'Commercial Vehicle','value':(dataSet['total_done_renewal_cv']||0)})
  dataset.productWiseDone.push({'label':'Four Wheeler','value':dataSet['total_done_renewal_four_wheeler']})
  dataset.productWiseDone.push({'label':'Two Wheeler','value':dataSet['total_done_renewal_two_wheeler']})
  return dataset;
};

  export default transformPolicyDoneStatsCard;
  