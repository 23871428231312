const pospListFormatter = (data, dataKey) => {

  const dataSet = JSON.parse(JSON.stringify(data.items||data));
  
    dataSet.forEach((element) => {
      element["label"] = element?.name + " - " + element?.code
      element["rm_name"] = element?.rm?.name
      element["total_active_renewal"] = 0
      element["pending"]=0
      element["done"]=0
      element["failed"]=0
      element["missed"]=0
      element.renewalData?.forEach((item)=>{
        element["total_active_renewal"] += (item?.count||0)
        if(item.status==="DUE"){
          element["pending"]=(item?.count||0)
        }
        if(item.status==="DONE"){
          element["done"]=(item?.count||0)
        }
        if(item.status==="MISSED"){
          element["missed"]=(item?.count||0)
        }
        if(item.status==="FAILED"){
          element["failed"]=(item?.count||0)
        }
      })
    });
  return dataSet;
};

  export default pospListFormatter;
  