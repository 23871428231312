const policyRenewalsAllListFilterFormatter = (data) => {
  console.log(data)
  const dataSet = data
  const valid_filter = ["existing_policy_id__customer_name", "existing_policy_number", "product_type", "status"]
  Object.keys(dataSet)?.forEach((element)=>{
    if(!dataSet[element]||!valid_filter.includes(element)){
      delete dataSet[element]
    }
  })
  return dataSet;
};

export default policyRenewalsAllListFilterFormatter;
  