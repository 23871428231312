const renewalDetailsFormatter = (data, renewalData) => {

  const dataSet = data;
  console.log(data, "data")
  dataSet["id"] = renewalData?.id
  dataSet["status"] = data?.status
  dataSet["failed_remark"] = data?.remark
  dataSet["new_policy_number"] = data?.new_policy_number
  return dataSet;
};

  export default renewalDetailsFormatter;
  