const pospDetailsFormatter = (data, dataKey) => {

  const dataSet = {};
  let dataset = JSON.parse(JSON.stringify(data?.data||data));
  dataset["rm_name"] = dataset?.rm?.name
  dataSet[dataKey] = dataset
  return dataSet;
};

  export default pospDetailsFormatter;
  