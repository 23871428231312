// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, PMText } from "lib";
import { MenuWidget } from "./MenuWidget";
import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";
function Leads() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const dispatch = useDispatch();
    dispatch(setStore({ currentPageTitle: "" }));

    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "Leads" }}>
                <PMGrid
                    item={true}
                    container={true}
                    md={3}
                    background="#007493"
                    sx={{
                        direction: "column",
                        width: "85vw",
                        minWidth: "85vw",
                        height: "90vh",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <PMText children="Feature Disabled" variant="h3" sx={{ opacity: ".3" }} color="info"></PMText>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default Leads;
