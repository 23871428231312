// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, FormWidget } from "lib";

import { useSnackbar } from "notistack";
import { errorSerializer } from "helperFn/errorSerializer";

import { useParsedPolicyUpdateMutation } from "store/apiSlice";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";

import { useParsedPolicyDetailsQuery } from "store/apiSlice";
function EditPolicy() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const { enqueueSnackbar } = useSnackbar();
    const [parsedPolicyUpdateMutationTrigger, parsedPolicyUpdateMutationResult] = useParsedPolicyUpdateMutation();
    const history = useNavigate();
    ({ id: page.parameters.route.id } = useParams());
    const dispatch = useDispatch();
    dispatch(setStore({ currentPageTitle: "" }));
    const parsedPolicyDetailsParams = { id: page.parameters.route.id };
    ({
        data: page.data.parsedPolicyDetailsData,
        isFetching: page.data.parsedPolicyDetailsDataIsFetching,
        isLoading: page.data.parsedPolicyDetailsDataIsLoading,
    } = useParsedPolicyDetailsQuery(parsedPolicyDetailsParams));

    const pocreated = (data) => {
        history("/parsed-policy-list/");
    };

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Policy Updated", { variant: "success" });
    };

    const parsedPolicyUpdate = async (data) => {
        var apiData = data;

        if (apiData) {
            parsedPolicyUpdateMutationTrigger({ id: apiData["id"], data: { ...apiData } })
                .unwrap()
                .then((data) => {
                    showSuccessAlert(data);
                    pocreated(data);
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(errorSerializer(error), { variant: "error" });
                });
        }
    };

    const parsedPolicyUpdateApi = (data) => {
        parsedPolicyUpdate(data);
    };

    return (
        <div className="builder_wrapper">
            <PMGrid container={true} xs={12}>
                <FormWidget
                    direction="column"
                    header="Create Stock Out Entry"
                    defaultValues={page.data.parsedPolicyDetailsData}
                    Onsubmit={parsedPolicyUpdateApi}
                    fieldsets={[
                        {
                            direction: "row",
                            header: "Customer Details",
                            grid: 3,
                            fields: [
                                { label: "Customer Name", name: "customer_name", type: "text" },
                                { label: "Mobile No", name: "customer_mobile_number", type: "text", required: true },
                                { label: "Address", name: "customer_address", type: "text", required: true },
                                { label: "City", name: "customer_city", type: "text" },
                                { label: "State", name: "customer_state", type: "text" },
                                { label: "Customer Group", name: "customer_group", type: "text" },
                                { label: "Vertical Type", name: "vertical_type", type: "text" },
                                { label: "Business Type", name: "business_type", type: "text" },
                                { label: "Org Type", name: "org_type", type: "text" },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            header: "Vehicle Details",
                            fields: [
                                { label: "Registration No", name: "vehicle_registration_number", type: "text" },
                                { label: "Make", name: "make", type: "text" },
                                { label: "Model", name: "model", type: "text" },
                                { label: "Variant", name: "variant", type: "text" },
                                { label: "Seating Capacity", name: "seating_capicity", type: "text" },
                                { label: "No of Wheels", name: "number_of_wheels", type: "text" },
                                { label: "GVW", name: "gross_vehicle_weight", type: "text" },
                                { label: "Engine Number", name: "engine_number", type: "text" },
                                { label: "Chasis Number", name: "chasis_number", type: "text" },
                                { label: "Fuel Type", name: "fuel_type", type: "text" },
                                { label: "Cubic Capacity", name: "cubic_capicity", type: "text" },
                                { label: "Sum Insured", name: "sum_insured", type: "text" },
                                { label: "Date Of Manunfacture", name: "date_of_manufacture", type: "text" },
                                { label: "Ncb", name: "no_claim_bonus", type: "text" },
                                {
                                    label: "Vehicle Registration Status",
                                    name: "vehicle_registrasion_status",
                                    type: "text",
                                },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Premium Details",
                            grid: 3,
                            fields: [
                                { label: "Od Premium", name: "basic_own_damage_premium", type: "text" },
                                { label: "OD Net Premium", name: "total_od_premium", type: "text" },
                                { label: "TP Premium", name: "basic_tp_premium", type: "text" },
                                { label: "TP Net Premium", name: "total_tp_premium", type: "text" },
                                { label: "Tax Amount", name: "gst", type: "text" },
                                { label: "Gross Premium", name: "total_premium", type: "text" },
                                { label: "Transaction Amount", name: "net_premium", type: "text" },
                                { label: "Tax Rate", name: "gst_rate", type: "text" },
                                { label: "Owner Driver", name: "pa_owner_driver_cover_premium", type: "text" },
                                {
                                    label: "Roadside Assistance",
                                    name: "roadside_assistance_cover_premium",
                                    type: "text",
                                },
                                { label: "Zero Dep Cover", name: "zero_depreciation_cover_premium", type: "text" },
                                {
                                    label: "Engine Protection Cover",
                                    name: "engine_protection_cover_premium",
                                    type: "text",
                                },
                                { label: "Stamp Duty", name: "stamp_duty", type: "text" },
                                { label: "Cess Rate", name: "gst_cess_rate", type: "text" },
                                { label: "Total Broker Payin Rate", name: "total_broker_payin_rate", type: "text" },
                                { label: "Broker Rate For TP", name: "broker_payin_rate_for_tp", type: "text" },
                                { label: "Broker Rate For OD", name: "broker_payin_rate_for_od", type: "text" },
                                { label: "Broker Payout Rate", name: "broker_payout_rate", type: "text" },
                                { label: "Broker Payout Rate For TP", name: "broker_payout_rate_on_tp", type: "text" },
                                { label: "Broker Reward Rate", name: "broker_reward_rate", type: "text" },
                                { label: "Broker Reward TP Rate", name: "broker_reward_rate_for_tp", type: "text" },
                                { label: "Broker Reward Rate On", name: "broker_reward_rate_based_on", type: "text" },
                                { label: "Ref Rate", name: "refferal_rate", type: "text" },
                                { label: "Ref Tp Rate", name: "refferal_rate_for_tp", type: "text" },
                                { label: "Pos Misp Rate", name: "pos_misp_rate", type: "text" },
                                { label: "Tp Pos Misp Rate", name: "tp_pos_misp_rate", type: "text" },
                                { label: "Csc Rate", name: "csc_rate", type: "text" },
                                { label: "Csc Rate", name: "exp_rate", type: "text" },
                                { label: "Csc Rate", name: "tp_exp_rate", type: "text" },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Policy Details",
                            grid: 3,
                            fields: [
                                { label: "Insurer", name: "insurer", type: "text" },
                                { label: "Insurer Branch Code", name: "insurer_branch_code", type: "text" },
                                { label: "Policy Type", name: "policy_type", type: "text" },
                                { label: "Policy No", name: "policy_number", type: "text" },
                                { label: "Start Date", name: "policy_start_date", type: "date" },
                                { label: "Expiry Date", name: "policy_end_date", type: "date" },
                                { label: "Policy Issue Date", name: "issue_date", type: "date" },
                                { label: "Policy Receive Date", name: "policy_receive_date", type: "date" },
                                { label: "Country", name: "country", type: "text" },
                                { label: "Broker Branch Code", name: "broker_branch_code", type: "text" },
                                { label: "Broker Branch", name: "broker_branch", type: "text" },
                                { label: "Pol Recieved Format", name: "pol_recieved_format", type: "text" },
                                { label: "Broker Biz Type", name: "broker_business_type", type: "text" },
                                { label: "Insurer Biz Type", name: "insurer_business_type", type: "text" },
                                { label: "Cover Note No", name: "covernote_number", type: "text" },
                                { label: "Policy Status", name: "policy_status", type: "text" },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Payment Details",
                            grid: 3,
                            fields: [
                                { label: "Mode", name: "mode", type: "text" },
                                { label: "Transaction Date", name: "transaction_date", type: "date" },
                                { label: "Receipt No", name: "receipt_number", type: "text" },
                                { label: "Premium Receipt No", name: "premium_receipt_number", type: "text" },
                                { label: "Premium Receipt Date", name: "premium_receipt_date", type: "date" },
                                { label: "Pay At", name: "pay_at", type: "text" },
                                { label: "Transaction No", name: "transaction_number", type: "text" },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Bussiness Details",
                            grid: 3,
                            fields: [
                                { label: "Ref Pos Misp", name: "ref_pos_misp", type: "text" },
                                { label: "Rm Code", name: "rm_id", type: "text" },
                                { label: "Solicit Code", name: "solicit_id", type: "text" },
                                { label: "Csc Code", name: "csc_code", type: "text" },
                                { label: "Tc Code", name: "tc_code", type: "text" },
                                { label: "Ref Pos Misp Code", name: "ref_pos_misp_code", type: "text" },
                                { label: "Campaign Name", name: "policy_source_campaigning_name", type: "text" },
                                { label: "Remarks", name: "remarks", type: "text" },
                            ],
                        },
                    ]}
                ></FormWidget>
            </PMGrid>
        </div>
    );
}
export default EditPolicy;
