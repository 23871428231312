const renewalDetailsFormatter = (data, renewalData) => {

  const dataSet = {};
  console.log(data, "data")
  dataSet[renewalData] = JSON.parse(JSON.stringify(data));
  if(dataSet[renewalData].reporting_manager_id){
    dataSet[renewalData]["manager"] = dataSet[renewalData]?.reporting_manager_id 
  }
  return dataSet;
};

  export default renewalDetailsFormatter;
  