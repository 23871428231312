const renewalDetailsFormatter = (data, renewalData) => {

  const dataSet = {};
  dataSet[renewalData] = {}
  data?.items?.forEach((element)=>{
    if(element.kyc_type==="AADHAR"){

      dataSet[renewalData]["aadhar_id"] = element.id
      dataSet[renewalData]["aadhar"] = element.data.AADHAR_NO
      dataSet[renewalData]["aadhar_document"] = element.document

      dataSet[renewalData]["aadhar_status_value"] = element?.status
      dataSet[renewalData]["aadhar_status"] = (element.status==="VERIFIED"||element.status==="REJECTED")
    }
    if(element.kyc_type==="PAN"){

      dataSet[renewalData]["pan_id"] = element.id
      dataSet[renewalData]["pan"] = element.data.PAN_NO
      dataSet[renewalData]["pan_document"] = element.document
      dataSet[renewalData]["pan_status_value"] = element?.status
      dataSet[renewalData]["pan_status"] = (element.status==="VERIFIED"||element.status==="REJECTED")
    }
  })
  console.log(dataSet, "==========")
  return dataSet;
};

  export default renewalDetailsFormatter;
  