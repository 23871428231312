const policyRenewalsListFormatter = (data, filter) => {

  const dataSet = {};
  if(data["status"]){
    filter["status"]=data["status"]
  }else{
    delete filter["status"]
  }
  return dataSet;
};

  export default policyRenewalsListFormatter;
  