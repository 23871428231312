// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, TableWidget } from "lib";

import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";

import { useParsedPolicyListQuery } from "store/apiSlice";
function ViewPolicies() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const dispatch = useDispatch();
    dispatch(setStore({ currentPageTitle: "" }));
    [page.parameters.parsedPolicyFilters, page.parameters.parsedPolicyFiltersSet] = useState({});
    [page.parameters.parsedPolicyPagination, page.parameters.parsedPolicyPaginationSet] = useState({ size: 50 });
    const parsedPolicyListParams = {
        ...page.parameters.parsedPolicyFilters,
        ...page.parameters.parsedPolicyPagination,
    };
    ({
        data: page.data.parsedPolicyListData,
        isFetching: page.data.parsedPolicyListDataIsFetching,
        isLoading: page.data.parsedPolicyListDataIsLoading,
    } = useParsedPolicyListQuery(parsedPolicyListParams));

    return (
        <div className="builder_wrapper">
            <PMGrid container={true} marginTop={2}>
                <TableWidget
                    header="Extracted Policies"
                    showSerialNumber={false}
                    xs={12}
                    tableApi={page.data.parsedPolicyListData || []}
                    tableParams={[page.parameters.parsedPolicyPagination, page.parameters.parsedPolicyPaginationSet]}
                    columns={[
                        { field: "created_at", headerName: "Extracted Date", type: "date" },
                        { field: "policy_number", headerName: "Policy No" },
                        { field: "insurer.name", headerName: "Insurer", highlight: true },
                        { field: "customer_name", headerName: "Customer Name" },
                        { field: "policy_type", headerName: "Policy Type" },
                        { field: "net_premium", headerName: "Net Premium", type: "currency" },
                        { field: "total_premium", headerName: "Total Premium", type: "currency", highlight: true },
                        { field: "sum_insured", headerName: "Sum Insured", type: "currency" },
                        { field: "policy_start_date", headerName: "Start Date", type: "date" },
                        { field: "policy_end_date", headerName: "End Date", type: "date" },
                        { field: "issue_date", headerName: "Policy Issue Date", type: "date" },
                        { headerName: "Edit Policy", type: "edit", url: "/edit-parsed-policy" },
                    ]}
                ></TableWidget>
            </PMGrid>
        </div>
    );
}
export default ViewPolicies;
