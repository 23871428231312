// @ts-nocheck

import { DetailCheckedWidget, DetailWidget, FormWidget, NotesWidget, PMGrid } from "lib";
import { useState } from "react";

import { errorSerializer } from "helperFn/errorSerializer";
import { useSnackbar } from "notistack";

import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useMarkAsFailedMutation } from "store/apiSlice";
import { setStore } from "store/appSlice";
import { default as markAsFailedFormatter } from "transformations/markAsFailedFormatter";

import { useRenewalDetailsQuery } from "store/apiSlice";
function RenewalDetails() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
            actionData:"",
        },
    };

    const { enqueueSnackbar } = useSnackbar();
    const [markAsFailedMutationTrigger, markAsFailedMutationResult] = useMarkAsFailedMutation();
    const history = useNavigate();
    ({ id: page.parameters.route.id } = useParams());
    const dispatch = useDispatch();
    dispatch(setStore({ currentPageTitle: "" }));
    [page.parameters.markAsFailedFormVisible, page.parameters.markAsFailedFormVisibleSet] = useState(false);
    [page.parameters.actionData, page.parameters.actionDataSet] = useState("");
    const renewalDetailsParams = { id: page.parameters.route.id };
    ({
        data: page.data.renewalDetailsData,
        isFetching: page.data.renewalDetailsDataIsFetching,
        isLoading: page.data.renewalDetailsDataIsLoading,
    } = useRenewalDetailsQuery(renewalDetailsParams));

    const renewalNavigate = (data) => {
        history("/policy-renewals-all");
    };

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Renewal Status Updated Successfully.", { variant: "success" });
    };

    const markAsFailed = async (data) => {
        var apiData = markAsFailedFormatter(data, page.data.renewalDetailsData);

        if (apiData) {
            markAsFailedMutationTrigger({
                id: apiData["id"],
                data: { failed_remark: apiData["remarks"], status: apiData["status"], new_policy_number: apiData["new_policy_number"] },
            })
                .unwrap()
                .then((data) => {
                    showSuccessAlert(data);
                    renewalNavigate(data);
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(errorSerializer(error), { variant: "error" });
                });
        }
    };

    const markAsFailedButtonSubmit = (data) => {
        markAsFailed(data);
    };

    const markAsFailedButtonClick = (data) => {
        toggleMarkAsFailedFormVisible(data);
    };

    const toggleMarkAsFailedFormVisible = (data) => {
        page.parameters.markAsFailedFormVisibleSet((currentValue) => !currentValue);
    };

    const handleActionChange = (data) => {
        page.parameters.actionDataSet(data)
    }
    return (
        <div className="builder_wrapper">
            <PMGrid item={true} container={true} direction="column" background="#FFFFFF">

                <PMGrid container={true} direction="row" md="12">

                {page.data.renewalDetailsData?.status == "FAILED" ? (
                    <DetailWidget
                    direction="row"
                    header="Existing Policy"
                    md={6}
                    fields={[
                        {
                            label: "Existing Policy Number",
                            value: page.data.renewalDetailsData?.existing_policy_number,
                        },
                        { label: "Renewal Status", value: page.data.renewalDetailsData?.status },
                        {
                            label: "Renewal Failed Remark",
                            value: page.data.renewalDetailsData?.failed_remark,
                        },
                    ]}
                    ></DetailWidget>
                    ) : (
                    <DetailWidget
                        direction="row"
                        header="Existing Policy"
                        md={6}
                        fields={[
                            {
                                label: "Existing Policy Number",
                                value: page.data.renewalDetailsData?.existing_policy_number,
                            },
                            { label: "Renewal Status", value: page.data.renewalDetailsData?.status },
                        ]}
                        ></DetailWidget>
                    
                )}
                        <DetailWidget
                            direction="row"
                            header="Sales Team Details"
                            md={6}
                            fields={[
                                { label: "POSP Name", value: page.data.renewalDetailsData?.posp?.name },
                                { label: "POSP Code", value: page.data.renewalDetailsData?.posp?.posp_code },
                            ]}
                        ></DetailWidget>
                </PMGrid>

                            <DetailWidget
                                direction="row"
                                header="Policy Details"
                                md={12}
                                fields={[
                                    { label: "Product Type", value: page.data.renewalDetailsData?.product_type },
                                    { label: "Coverage Type", value: page.data.renewalDetailsData?.coverage_type },
                                    { label: "Insurer", value: page.data.renewalDetailsData?.insurer?.name },
                                    {
                                        label: "Sum Insured/IDV",
                                        value: page.data.renewalDetailsData?.existing_policy.sum_insured,
                                        type: "currency",
                                    },
                                    { label: "Renewal Due Date", value: page.data.renewalDetailsData?.due_date, type: "date" },
                                ]}
                            ></DetailWidget>

                <PMGrid container={true} direction="row" md="12">
                    <DetailWidget
                        direction="column"
                        header="Customer Details"
                        md={6}
                        fields={[
                            {
                                label: "Customer Name",
                                value: page.data.renewalDetailsData?.existing_policy?.customer_name,
                            },
                            {
                                label: "Mobile No",
                                value: page.data.renewalDetailsData?.existing_policy?.customer_phone,
                            },
                            { label: "Email", value: page.data.renewalDetailsData?.existing_policy?.customer_email },
                            {
                                label: "Address",
                                value: page.data.renewalDetailsData?.existing_policy?.customer_address,
                            },{
                                label: "Pincode",
                                value: page.data.renewalDetailsData?.existing_policy?.customer_pincode,
                            },
                        ]}
                    ></DetailWidget>

                    <DetailWidget
                        direction="row"
                        header="Vehicle Details"
                        columns={4}
                        md={6}
                        fields={[
                            {
                                label: "Reg No",
                                value: page.data.renewalDetailsData?.existing_policy?.registration_number,
                            },
                            { label: "Make", value: page.data.renewalDetailsData?.make },
                            { label: "Model", value: page.data.renewalDetailsData?.model },
                            { label: "Variant", value: page.data.renewalDetailsData?.variant },
                            {
                                label: "Manufacturing Year",
                                value: page.data.renewalDetailsData?.existing_policy?.manufacturing_year,
                            },
                            {
                                label: "Registration Date",
                                value: page.data.renewalDetailsData?.existing_policy?.registration_date,
                            },
                            { label: "RTO City", value: page.data.renewalDetailsData?.rto_city },
                            { label: "RTO State", value: page.data.renewalDetailsData?.rto_state },
                            { label: "RTO Code", value: page.data.renewalDetailsData?.rto_code },
                            { label: "Engine No", value: page.data.renewalDetailsData?.existing_policy?.engine_number },
                            { label: "Chassis No", value: page.data.renewalDetailsData?.existing_policy?.chasis_number },
                        ]}
                    ></DetailWidget>
                </PMGrid>

                <DetailWidget
                    direction="row"
                    header="Premium Details (Existing Insurer)"
                    md={12}
                    fields={[
                        {
                            label: "OD Premium",
                            type: "currency",
                            value: page.data.renewalDetailsData?.existing_policy?.basic_od_premium,
                        },
                        { label: "NCB", value: (page.data.renewalDetailsData?.existing_policy?.ncb||0) + "%" },
                        {
                            label: "Total OD Premium",
                            type: "currency",
                            value: page.data.renewalDetailsData?.existing_policy?.total_od_premium,
                        },
                        {
                            label: "Liability Premium",
                            type: "currency",
                            value: page.data.renewalDetailsData?.existing_policy?.basic_tp_premium,
                        },
                        {
                            label: "Total Liability Premium",
                            type: "currency",
                            value: page.data.renewalDetailsData?.existing_policy?.total_tp_premium,
                        },
                    ]}
                ></DetailWidget>

                <DetailWidget
                    direction="row"
                    header="Addons Details (Existing Insurer)"
                    md={12}
                    fields={[
                        {
                            label: "Zero Dep Cover",
                            type: "currency",
                            value: page.data.renewalDetailsData?.existing_policy?.zero_dep_premium,
                        },{
                            label: "PA Cover",
                            type: "currency",
                            value: page.data.renewalDetailsData?.existing_policy?.pa_cover_premium,
                        },
                        {
                            label: "Engine Cover",
                            type: "currency",
                            value: page.data.renewalDetailsData?.existing_policy?.engine_cover_premium,
                        },
                        {
                            label: "IMT 34 Cover",
                            type: "currency",
                            value: page.data.renewalDetailsData?.existing_policy?.imt_34_cover,
                        },
                        {
                            label: "Roadside Assitance",
                            type: "currency",
                            value: page.data.renewalDetailsData?.existing_policy?.roadside_premium,
                        }
                    ]}
                ></DetailWidget>

                <DetailWidget
                    direction="row"
                    header="Total Premium Details (Existing Insurer)"
                    md={12}
                    fields={[
                        {
                            label: "Net Premium",
                            type: "currency",
                            value: page.data.renewalDetailsData?.existing_policy?.net_premium,
                        },
                        { label: "GST", value: page.data.renewalDetailsData?.existing_policy?.service_tax, type: "currency" },
                        {
                            label: "Total Premium",
                            type: "currency",
                            value: page.data.renewalDetailsData?.existing_policy?.total_premium,
                        },
                    ]}
                ></DetailWidget>
                <PMGrid container={true} direction="row" justifyContent="space-around">
                <PMGrid width={page.data.renewalDetailsData?.status!="DONE" && page.data.renewalDetailsData?.status!="FAILED" ? "50%" : "100%"}>
                <DetailCheckedWidget
                    direction="column"
                    width="100%"

                    header="Automated Reminders"
                    fields={[
                        {
                            label: "1st Reminder: (T-60 Days)",
                            data: [
                                { name: "Customer", icon: (page.data.renewalDetailsData?.t_sixty_reminder_customer_check ? "check_box": "check_box_outline_blank_icon") },
                                { name: "POSP", icon: (page.data.renewalDetailsData?.t_sixty_reminder_posp_check ? "check_box": "check_box_outline_blank_icon") },
                            ],
                        },
                        {
                            label: "2nd Reminder: (T-30 Days)",
                            data: [
                                { name: "Customer", icon: (page.data.renewalDetailsData?.t_thirty_reminder_customer_check ? "check_box": "check_box_outline_blank_icon") },
                                { name: "POSP", icon: (page.data.renewalDetailsData?.t_thirty_reminder_posp_check ? "check_box": "check_box_outline_blank_icon") },
                            ],
                        },
                        {
                            label: "3rd Reminder: (T-7 Days)",
                            data: [
                                { name: "Customer", icon: (page.data.renewalDetailsData?.t_seven_reminder_customer_check ? "check_box": "check_box_outline_blank_icon") },
                                { name: "POSP", icon: (page.data.renewalDetailsData?.t_seven_reminder_posp_check ? "check_box": "check_box_outline_blank_icon") },
                                { name: "RM", icon: (page.data.renewalDetailsData?.t_seven_reminder_rm_check ? "check_box": "check_box_outline_blank_icon") },
                            ],
                        },
                    ]}
                ></DetailCheckedWidget>
                </PMGrid>
                {page.data.renewalDetailsData?.status != "FAILED" && page.data.renewalDetailsData?.status != "DONE" ? 
                <PMGrid  width="50%">
                    <FormWidget
                        header="Update Renewal Status"
                        submitButtonLabel="Submit"
                        Onsubmit={markAsFailedButtonSubmit}
                        fieldsets={[
                            {
                                direction: "row",
                                md: 12,
                                fields: [
                                    {
                                        label: "Status",
                                        name: "status",
                                        type: "select",
                                        required: true,

                                    onChange: handleActionChange,
                                        options: [
                                            { id: "FAILED", label: "Mark as Failed" },
                                            {
                                                id: "DONE",
                                                label: "Mark as Done",
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                direction: "row",
                                fields: [
                                    {
                                        label: "Remark",
                                        name: "remarks",
                                        type: "select",
                                        md:12,
                                        required: page.parameters?.actionData=="FAILED",
                                        displayon: page.parameters?.actionData!="FAILED",
                                        options: [
                                            { id: "Custmer Not Reachable", label: "Customer Phone is Unavailable" },
                                            {
                                                id: "Policy already issued with Another Intermediary",
                                                label: "Customer Already purchased the Policy",
                                            },
                                        ],
                                    },
                                    {
                                        label: "Policy Number",
                                        md:12,
                                        name: "new_policy_number",
                                        type: "text",
                                        required: page.parameters?.actionData=="DONE",
                                        displayon: page.parameters?.actionData!="DONE",
                                       
                                    },
                                ],
                            },
                        ]}
                    ></FormWidget>
                    <NotesWidget sx={{marginTop:4}} 
                    direction="column"
                    columns={1}
                    textColor="secondary"
                    header="Note:"
                    md={12} fields={[{label:"The following system is a demo environment. When you select 'Mark as done' only renewal's status is changed to DONE. It is important to note that this action does not create a new policy. "}]
                    }></NotesWidget>
                    </PMGrid>:""}
                    </PMGrid>
            </PMGrid>
        </div>
    );
}
export default RenewalDetails;
