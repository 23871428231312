// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, DetailWidget, FormWidget, TableWidget } from "lib";
import { MenuWidget } from "./MenuWidget";
import { useSelector } from "react-redux";

import { usePospDetailQuery } from "store/apiSlice";

import { useRenewalInteractionHistoryQuery } from "store/apiSlice";

import { usePospDetailStatsQuery } from "store/apiSlice";

import { usePolicyListQuery } from "store/apiSlice";

import { usePolicyRenewalsListQuery } from "store/apiSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "helperFn/errorSerializer";

import { useInteractionCreateMutation } from "store/apiSlice";
import { jsonToFormData } from "helperFn/formData";
import { default as pospinteractionCreateFormatter } from "transformations/pospinteractionCreateFormatter";
import { useDispatch } from "react-redux";
import { setStore } from "store/appSlice";
import { default as pospDetailFormatter } from "transformations/pospDetailFormatter";
import { default as renewalInteractionHistoryDataFormatter } from "transformations/renewalInteractionHistoryDataFormatter";
import { default as pospDetailStatsFormatter } from "transformations/pospDetailStatsFormatter";
import { default as policyRenewalsListFormatter } from "transformations/policyRenewalsListFormatter";
import { default as policyListListFormatter } from "transformations/policyListListFormatter";
import { default as policyRenewalsListFilterFormatter } from "transformations/policyRenewalsListFilterFormatter";
import { useParams } from "react-router-dom";
function POSPRenewalDetails() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const pospDetailData = useSelector((states) => states?.appStore?.pospDetailData);
    const [pospDetailSkip, setpospDetailSkip] = useState(true);
    const [pospDetailParams, setpospDetailParams] = useState({});
    const pospDetailQuery = usePospDetailQuery(pospDetailParams, { skip: pospDetailSkip });
    const [renewalInteractionHistorySkip, setrenewalInteractionHistorySkip] = useState(true);
    const [renewalInteractionHistoryParams, setrenewalInteractionHistoryParams] = useState({});
    const renewalInteractionHistoryQuery = useRenewalInteractionHistoryQuery(renewalInteractionHistoryParams, {
        skip: renewalInteractionHistorySkip,
    });
    const [pospDetailStatsSkip, setpospDetailStatsSkip] = useState(true);
    const [pospDetailStatsParams, setpospDetailStatsParams] = useState({});
    const pospDetailStatsQuery = usePospDetailStatsQuery(pospDetailStatsParams, { skip: pospDetailStatsSkip });
    const [policyListSkip, setpolicyListSkip] = useState(true);
    const [policyListParams, setpolicyListParams] = useState({});
    const policyListQuery = usePolicyListQuery(policyListParams, { skip: policyListSkip });
    const [policyRenewalsListSkip, setpolicyRenewalsListSkip] = useState(true);
    const [policyRenewalsListParams, setpolicyRenewalsListParams] = useState({});
    const policyRenewalsListQuery = usePolicyRenewalsListQuery(policyRenewalsListParams, {
        skip: policyRenewalsListSkip,
    });
    const { enqueueSnackbar } = useSnackbar();
    const [interactionCreateMutationTrigger, interactionCreateMutationResult] = useInteractionCreateMutation();
    const dispatch = useDispatch();
    const [formattedData, setformattedData] = useState([]);
    const [filterState, setfilterState] = useState(false);
    const policyRenewalsListData = useSelector((states) => states?.appStore?.policyRenewalsListData);
    const policyRenewalsFilterListData = useSelector((states) => states?.appStore?.policyRenewalsFilterListData);
    const pospDetailStatsData = useSelector((states) => states?.appStore?.pospDetailStatsData);
    const renewalInteractionHistoryData = useSelector((states) => states?.appStore?.renewalInteractionHistoryData);
    ({ id: page.parameters.route.id } = useParams());
    dispatch(setStore({ currentPageTitle: "" }));

    const setFilterParams = (data) => {
        const responseData = data?.data || data;
        setpolicyRenewalsListFilterParams(
            policyRenewalsListFilterFormatter(responseData, policyRenewalsListFilterParams)
        );
    };

    const filterSubmit = (data) => {
        setFilterState(data);

        setFilterParams(data);
    };

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Interaction Created", { variant: "success" });
    };

    const interactionCreate = async (data) => {
        var apiData = pospinteractionCreateFormatter(data, pospDetailData);

        if (apiData) {
            interactionCreateMutationTrigger(
                jsonToFormData({
                    content: apiData["content"],
                    interaction_with: apiData["interaction_with"],
                    interaction_status: apiData["interaction_status"],
                    target: apiData["target"],
                    entity_type: apiData["entity_type"],
                    entity_id: apiData["entity_id"],
                    receiver: apiData["receiver"],
                    agent_id: apiData["agent_id"],
                    subject: apiData["subject"],
                    actual_time: apiData["actual_time"],
                    type: apiData["type"],
                    files: apiData["files"],
                })
            )
                .unwrap()
                .then((data) => {
                    setRenewalsInteractionHistorySkip(data);
                    showSuccessAlert(data);
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(errorSerializer(error), { variant: "error" });
                });
        }
    };

    const interactionSubmitApi = (data) => {
        interactionCreate(data);
    };

    const updatePolicyRenewalsList = (data) => {
        const responseData = data?.data || data;
        setformattedData(policyListListFormatter(responseData, policyRenewalsListData));
    };

    const policyListSuccess = (data) => {
        updatePolicyRenewalsList(data);
    };
    useEffect(() => {
        if (policyListQuery.isSuccess) {
            policyListSuccess(policyListQuery);
        } else if (policyListQuery.isError) {
            policyListSuccess(null);
        }
    }, [policyListQuery]);
    const policyList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setpolicyListSkip(false);
    };

    const policyRenewalsListListData = (data) => {
        var formatedData = {
            ...policyRenewalsListFormatter(data?.data || data, "policyRenewalsListData"),
        };
        dispatch(setStore(formatedData));
    };

    const policyRenewalsListSuccess = (data) => {
        policyRenewalsListListData(data);
        policyList(data);
    };
    useEffect(() => {
        if (policyRenewalsListQuery.isSuccess) {
            policyRenewalsListSuccess(policyRenewalsListQuery);
        } else if (policyRenewalsListQuery.isError) {
            policyRenewalsListSuccess(null);
        }
    }, [policyRenewalsListQuery]);
    const policyRenewalsList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setpolicyRenewalsListParams({ order_by: "-due_date", posp_id: id });
        setpolicyRenewalsListSkip(false);
    };

    useEffect(() => {
        policyRenewalsList();
    }, [id]);

    const pospDetailStatsDetailData = (data) => {
        var formatedData = {
            ...pospDetailStatsFormatter(data?.data || data, "pospDetailStatsData"),
        };
        dispatch(setStore(formatedData));
    };

    const pospDetailStatsSuccess = (data) => {
        pospDetailStatsDetailData(data);
    };
    useEffect(() => {
        if (pospDetailStatsQuery.isSuccess) {
            pospDetailStatsSuccess(pospDetailStatsQuery);
        } else if (pospDetailStatsQuery.isError) {
            pospDetailStatsSuccess(null);
        }
    }, [pospDetailStatsQuery]);
    const pospDetailStats = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setpospDetailStatsParams({ id: id });
        setpospDetailStatsSkip(false);
    };

    useEffect(() => {
        pospDetailStats();
    }, [id]);

    const renewalInteractionHistoryDataData = (data) => {
        var formatedData = {
            ...renewalInteractionHistoryDataFormatter(data?.data || data, "renewalInteractionHistoryData"),
        };
        dispatch(setStore(formatedData));
    };

    const renewalInteractionHistorySuccess = (data) => {
        renewalInteractionHistoryDataData(data);
    };
    useEffect(() => {
        if (renewalInteractionHistoryQuery.isSuccess) {
            renewalInteractionHistorySuccess(renewalInteractionHistoryQuery);
        } else if (renewalInteractionHistoryQuery.isError) {
            renewalInteractionHistorySuccess(null);
        }
    }, [renewalInteractionHistoryQuery]);
    const renewalInteractionHistory = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setrenewalInteractionHistoryParams({ entity_type: "POSP", order_by: "created_at", entity_id: id });
        setrenewalInteractionHistorySkip(!renewalInteractionHistorySkip);
    };

    useEffect(() => {
        renewalInteractionHistory();
    }, [id]);

    const pospDetailDetailData = (data) => {
        var formatedData = {
            ...pospDetailFormatter(data?.data || data, "pospDetailData"),
        };
        dispatch(setStore(formatedData));
    };

    const pospDetailSuccess = (data) => {
        pospDetailDetailData(data);
    };
    useEffect(() => {
        if (pospDetailQuery.isSuccess) {
            pospDetailSuccess(pospDetailQuery);
        } else if (pospDetailQuery.isError) {
            pospDetailSuccess(null);
        }
    }, [pospDetailQuery]);
    const pospDetail = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setpospDetailParams({ id: id });
        setpospDetailSkip(false);
    };

    useEffect(() => {
        pospDetail();
    }, [id]);

    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "POSP Detail" }}>
                <PMGrid container={true} md={12} direction="row" sx={{ my: 2 }}>
                    <PMGrid container={true} md={6}>
                        <PMGrid item={true} container={true} direction="column" background="#FFFFFF">
                            <DetailWidget
                                direction="row"
                                header="POSP Details"
                                md={9}
                                data={pospDetailData || []}
                                fields={[
                                    { label: "POSP Name", key: "name" },
                                    { label: "POSP Code", key: "posp_code" },
                                    { label: "RM Name", key: "rm_name" },
                                ]}
                            ></DetailWidget>
                        </PMGrid>

                        <PMGrid container={true}>
                            <FormWidget
                                sx={{ my: 2 }}
                                header="Create Interaction with POSP"
                                submitButtonLabel="Send"
                                Onsubmit={interactionSubmitApi}
                                fieldsets={[
                                    {
                                        direction: "rows",
                                        grid: 12,
                                        fields: [
                                            { label: "Message", name: "message", type: "textarea", required: true },
                                            {
                                                label: "Mode",
                                                name: "mode",
                                                type: "select",
                                                required: true,
                                                options: [
                                                    { id: "EMAIL", label: "Email" },
                                                    { id: "WHATSAPP", label: "WhatsApp" },
                                                    { id: "SMS", label: "SMS" },
                                                ],
                                            },
                                            { label: "Attach Document", name: "document", type: "file" },
                                        ],
                                    },
                                ]}
                            ></FormWidget>
                        </PMGrid>
                    </PMGrid>

                    <PMGrid container={true} md={6}>
                        <TableWidget
                            header="Interaction History"
                            showSerialNumber={false}
                            showQuickFilter={false}
                            pageSize={6}
                            xs={12}
                            rows={{ name: "renewalInteractionHistoryData" }}
                            columns={[
                                { field: "created_at", headerName: "Created At", type: "datetime" },
                                { field: "type", headerName: "Mode" },
                                { field: "content", headerName: "Message" },
                            ]}
                        ></TableWidget>
                    </PMGrid>
                </PMGrid>

                <PMGrid container={true} justifyContent="right">
                    <PMGrid container={true} xs={6}>
                        <FormWidget
                            submitButtonLabel="Filter"
                            showSubmitButton={false}
                            fullWidth="true"
                            Onchange={filterSubmit}
                            fieldsets={[
                                {
                                    direction: "column",
                                    fields: [
                                        {
                                            label: "Select Status",
                                            name: "status",
                                            type: "select",
                                            md: "6",
                                            options: [
                                                { id: "", label: "--" },
                                                { id: "DONE", label: "Done" },
                                                { id: "DUE", label: "Pending" },
                                                { id: "MISSED", label: "Missed" },
                                                { id: "FAILED", label: "Failed" },
                                            ],
                                            required: true,
                                        },
                                    ],
                                },
                            ]}
                        ></FormWidget>
                    </PMGrid>
                </PMGrid>

                <PMGrid container={true} marginTop={2}>
                    <TableWidget
                        showSerialNumber={false}
                        showQuickFilter={false}
                        header="POSP Renewals LIst"
                        xs={12}
                        rows={{ name: "formattedData" }}
                        columns={[
                            { field: "due_date", headerName: "Due Date", type: "date", flex: 0.7 },
                            { field: "existing_policy_number", headerName: "Policy Number", flex: 1.2 },
                            { field: "product_type", headerName: "Product Type", flex: 1.2 },
                            { field: "insurer_name", headerName: "Insurer", flex: 1.7 },
                            { field: "customer_name", headerName: "Customer Name" },
                            { field: "total_premium", headerName: "Total Premium", type: "currency" },
                            { field: "insurer_renewal_notice", headerName: "Insurer RN", flex: 0.5, type: "download" },
                            { field: "broker_renewal_notice", headerName: "My RN", flex: 0.5, type: "download" },
                            {
                                field: "status",
                                headerName: "Renewal Status",
                                type: "chip",
                                condition: [
                                    { rule: "MISSED", color: "error" },
                                    { rule: "PENDING", color: "warning" },
                                    { rule: "DONE", color: "success" },
                                ],
                                flex: 0.6,
                            },
                            {
                                field: "View",
                                headerName: "View",
                                type: "edit",
                                url: "/renewal",
                                flex: 0.6,
                                condition: [],
                            },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default POSPRenewalDetails;
