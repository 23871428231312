const transformPolicyAllStatsCard = (data) => {
    const incomingData = JSON.parse(JSON.stringify(data?.items || data));
    const dataset = { intervalDone: [], pendingDone: [], missedFailed: [], totalPremiumDone: [] };

    // console.log(incomingData, "==============================");

    dataset.intervalDone.push({ label: "Change", value: incomingData["renewal_change"], type: "percentage" });
    dataset.intervalDone.push({
        label: "Renewal Done (MTD)",
        value: incomingData["renewal_this_month"],
        type: "percentage",
    });
    dataset.intervalDone.push({
        label: "Renewal Done (Last Month)",
        value: incomingData["renewal_last_month"],
        type: "percentage",
    });
    dataset.pendingDone.push({ label: "Total Renewals Due", value: incomingData["total_due_renewal"] });
    dataset.pendingDone.push({ label: "Pending", value: incomingData["total_pending_due_renewal"] });
    dataset.pendingDone.push({ label: "Done", value: incomingData["total_done_due_renewal"] });
    dataset.totalPremiumDone.push({
        label: "Total Premium Due",
        value: incomingData["total_premium"],
        type: "currency",
    });
    dataset.totalPremiumDone.push({
        label: "Premium Pending",
        value: incomingData["total_premium_pending"],
        type: "currency",
    });
    dataset.totalPremiumDone.push({
        label: "Premium Done",
        value: incomingData["total_premium_done"],
        type: "currency",
    });
    dataset.missedFailed.push({ label: "Total Renewals Due", value: incomingData["total_renewal"] });
    dataset.missedFailed.push({ label: "Missed", value: incomingData["total_missed_renewal"] });
    dataset.missedFailed.push({ label: "Lost", value: incomingData["total_lost_renewal"] });

    return dataset;
};

export default transformPolicyAllStatsCard;
