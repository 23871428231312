// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, PMText, FormWidget, TableWidget } from "lib";

import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";

import { usePospListQuery } from "store/apiSlice";

import { usePospListAutocompleteQuery } from "store/apiSlice";

import { useRenewalStatsPospListQuery } from "store/apiSlice";

import { usePospDirectRenewalQuery } from "store/apiSlice";
function POSPRenewalsList() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const dispatch = useDispatch();
    dispatch(setStore({ currentPageTitle: "" }));
    [page.parameters.pospListFilters, page.parameters.pospListFiltersSet] = useState({});
    [page.parameters.pospListPagination, page.parameters.pospListPaginationSet] = useState({ size: 50 });
    const pospListParams = {
        order_by: "-start_date",
        ...page.parameters.pospListFilters,
        ...page.parameters.pospListPagination,
    };
    ({
        data: page.data.pospListData,
        isFetching: page.data.pospListDataIsFetching,
        isLoading: page.data.pospListDataIsLoading,
    } = usePospListQuery(pospListParams));
    const pospListAutocompleteParams = { order_by: "-created_at" };
    ({
        data: page.data.pospListAutocompleteData,
        isFetching: page.data.pospListAutocompleteDataIsFetching,
        isLoading: page.data.pospListAutocompleteDataIsLoading,
    } = usePospListAutocompleteQuery(pospListAutocompleteParams));
    const renewalStatsPospListParams = { order_by: "-created_at" };
    ({
        data: page.data.renewalStatsPospListData,
        isFetching: page.data.renewalStatsPospListDataIsFetching,
        isLoading: page.data.renewalStatsPospListDataIsLoading,
    } = useRenewalStatsPospListQuery(renewalStatsPospListParams));
    const pospDirectRenewalParams = { order_by: "-created_at" };
    ({
        data: page.data.pospDirectRenewalData,
        isFetching: page.data.pospDirectRenewalDataIsFetching,
        isLoading: page.data.pospDirectRenewalDataIsLoading,
    } = usePospDirectRenewalQuery(pospDirectRenewalParams));

    const filterChange = (data) => {
        setPOSPListFilters(data);
    };

    const setPOSPListFilters = (data) => {
        var parameterValue = data;

        page.parameters.pospListFiltersSet(parameterValue);
    };

    return (
        <div className="builder_wrapper">
            <PMGrid>
                <PMText children="POSP - Renewals" variant="h6"></PMText>
            </PMGrid>

            <PMGrid container={true} marginTop={2}>
                <FormWidget
                    showSubmitButton={false}
                    header="Apply Filter"
                    submitButtonLabel="Search"
                    fullWidth="true"
                    submitOnChange={true}
                    Onsubmit={filterChange}
                    fieldsets={[
                        {
                            direction: "row",
                            fields: [
                                {
                                    label: "Search by POSP Name, POSP Code",
                                    name: "posp_id",
                                    type: "autocomplete",
                                    md: "6",
                                    options: page.data.pospListAutocompleteData?.items || [],
                                    required: true,
                                },
                                {
                                    label: "Select Duration",
                                    name: "interval",
                                    type: "select",
                                    md: "6",
                                    options: [
                                        { id: "", label: "---" },
                                        { id: "next_seven_days", label: "Next Seven Days" },
                                        { id: "next_thirty_days", label: "Next Thirty Days" },
                                        { id: "next_sixty_days", label: "Next Sixty Days" },
                                        { id: "in_grace_period", label: "In Grace Period" },
                                    ],
                                    required: true,
                                },
                            ],
                        },
                    ]}
                ></FormWidget>
            </PMGrid>

            <PMGrid container={true} marginTop={2}>
                <TableWidget
                    showSerialNumber={false}
                    header="POSP List"
                    showQuickFilter={false}
                    xs={12}
                    tableApi={page.data.pospListData || []}
                    tableParams={[page.parameters.pospListPagination, page.parameters.pospListPaginationSet]}
                    columns={[
                        { field: "name", headerName: "POSP Name" },
                        { field: "posp_code", headerName: "POSP Code" },
                        { field: "rm_name", headerName: "RM" },
                        { field: "total_active_renewal", headerName: "Total Renewals Due" },
                        { field: "done", headerName: "Done" },
                        { field: "pending", headerName: "Pending" },
                        { field: "missed", headerName: "Missed" },
                        { field: "failed", headerName: "Lost" },
                        {
                            field: "View",
                            headerName: "View",
                            type: "edit",
                            url: "/posp-renewals",
                            flex: 0.8,
                            condition: [],
                        },
                    ]}
                ></TableWidget>
            </PMGrid>
        </div>
    );
}
export default POSPRenewalsList;
