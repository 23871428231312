// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, MetricWidget2, TableWidget } from "lib";

import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";

import { usePospDashboardRenewalStatsQuery } from "store/apiSlice";

import { useZoneRenewalsListQuery } from "store/apiSlice";
function POSPRenewalsDashboard() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const dispatch = useDispatch();
    dispatch(setStore({ currentPageTitle: "" }));
    [page.parameters.pospRenewalsFilters, page.parameters.pospRenewalsFiltersSet] = useState({});
    [page.parameters.pospRenewalsPagination, page.parameters.pospRenewalsPaginationSet] = useState({ size: 50 });
    const pospDashboardRenewalStatsParams = {};
    ({
        data: page.data.pospDashboardRenewalStatsData,
        isFetching: page.data.pospDashboardRenewalStatsDataIsFetching,
        isLoading: page.data.pospDashboardRenewalStatsDataIsLoading,
    } = usePospDashboardRenewalStatsQuery(pospDashboardRenewalStatsParams));
    const zoneRenewalsListParams = {
        ...page.parameters.pospRenewalsFilters,
        ...page.parameters.pospRenewalsPagination,
    };
    ({
        data: page.data.zoneRenewalsListData,
        isFetching: page.data.zoneRenewalsListDataIsFetching,
        isLoading: page.data.zoneRenewalsListDataIsLoading,
    } = useZoneRenewalsListQuery(zoneRenewalsListParams));

    return (
        <div className="builder_wrapper">
            <PMGrid container={true} marginTop={2}>
                <MetricWidget2
                    xs={3}
                    metricIcon="inventory"
                    metricValueColor="primary"
                    metricIconColor="primary"
                    type="2"
                    fields={page.data.pospDashboardRenewalStatsData?.status.total}
                ></MetricWidget2>

                <MetricWidget2
                    xs={3}
                    metricIcon="trending_down"
                    metricValueColor="success.main"
                    metricIconColor="success"
                    type="3"
                    fields={page.data.pospDashboardRenewalStatsData?.status.done}
                ></MetricWidget2>

                <MetricWidget2
                    xs={3}
                    metricIcon="layers_clear"
                    metricValueColor="warning.main"
                    metricIconColor="warning"
                    type="3"
                    fields={page.data.pospDashboardRenewalStatsData?.status.pending}
                ></MetricWidget2>

                <MetricWidget2
                    xs={3}
                    metricIcon="domain_verification"
                    metricValueColor="error.main"
                    metricIconColor="error"
                    type="3"
                    fields={page.data.pospDashboardRenewalStatsData?.status.missed}
                ></MetricWidget2>
            </PMGrid>

            <PMGrid container={true} marginTop={2} header="Product Wise Dashboard">
                <MetricWidget2
                    xs={3}
                    metricIcon="inventory"
                    metricValueColor="primary"
                    metricIconColor="primary"
                    type="3"
                    fields={page.data.pospDashboardRenewalStatsData?.product_type?.cv}
                ></MetricWidget2>

                <MetricWidget2
                    xs={3}
                    metricIcon="layers_clear"
                    metricValueColor="warning.main"
                    metricIconColor="warning"
                    type="3"
                    fields={page.data.pospDashboardRenewalStatsData?.product_type?.fw}
                ></MetricWidget2>

                <MetricWidget2
                    xs={3}
                    metricIcon="domain_verification"
                    metricValueColor="error.main"
                    metricIconColor="error"
                    type="3"
                    fields={page.data.pospDashboardRenewalStatsData?.product_type?.tw}
                ></MetricWidget2>
            </PMGrid>

            <PMGrid container={true} marginTop={2}>
                <TableWidget
                    header="Zone wise Renewals Data"
                    showSerialNumber={false}
                    showQuickFilter={false}
                    hideFooter={true}
                    xs={12}
                    tableApi={page.data.zoneRenewalsListData || []}
                    tableParams={[page.parameters.pospRenewalsPagination, page.parameters.pospRenewalsPaginationSet]}
                    columns={[
                        { field: "zone", headerName: "Zone", type: "relatedlink" },
                        { field: "total_renewals", headerName: "Total Renewals" },
                        { field: "total_premium", headerName: "Total Premium", type: "currency" },
                        { field: "pending", headerName: "Pending" },
                        { field: "done", headerName: "Done" },
                        { field: "missed", headerName: "Missed" },
                    ]}
                ></TableWidget>
            </PMGrid>
        </div>
    );
}
export default POSPRenewalsDashboard;
