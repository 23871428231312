const transformPolicyPendingStatsCard = (data) => {
  const dataSet = JSON.parse(JSON.stringify(data?.items||data));
  const dataset = {'intervalPending':[], 'productWisePending':[], 'totalPremiumPending':[]}
  console.log(dataSet, '==============================')
  dataset.intervalPending.push({'label':'Total Renewals Pending','value':dataSet['total_pending_renewal']})
  dataset.intervalPending.push({'label':'Next Seven Days','value':dataSet['total_pending_renewal_7_days']})
  dataset.intervalPending.push({'label':'Next Thirty Days','value':dataSet['total_pending_renewal_30_days']})
  dataset.totalPremiumPending.push({'label':'Total Premium Pending','value':dataSet['total_premium_pending'], type:'currency'})
  dataset.totalPremiumPending.push({'label':'Next Seven Days','value':dataSet['total_premium_pending_7_days'], type:'currency'})
  dataset.totalPremiumPending.push({'label':'Next Thirty Days','value':dataSet['total_premium_pending_30_days'], type:'currency'})
  dataset.productWisePending.push({'label':'Commercial Vehicle', 'value':(dataSet['total_pending_renewal_cv']||0)})
  dataset.productWisePending.push({'label':'Four Wheeler','value':dataSet['total_pending_renewal_four_wheeler']})
  dataset.productWisePending.push({'label':'Two Wheeler','value':dataSet['total_pending_renewal_two_wheeler']})
  return dataset;
};

  export default transformPolicyPendingStatsCard;
  