// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, TableWidget } from "lib";

import { useParams } from "react-router-dom";
import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";

import { useZoneRenewalsDoneListQuery } from "store/apiSlice";
function ZoneRenewalsList() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    ({ sales_zone: page.parameters.route.sales_zone } = useParams());
    const dispatch = useDispatch();
    dispatch(setStore({ currentPageTitle: "" }));
    [page.parameters.renewalListFilters, page.parameters.renewalListFiltersSet] = useState({});
    [page.parameters.renewalListPagination, page.parameters.renewalListPaginationSet] = useState({ size: 50 });
    const zoneRenewalsDoneListParams = {
        sales_zone: page.parameters.route.sales_zone,
        ...page.parameters.renewalListFilters,
        ...page.parameters.renewalListPagination,
    };
    ({
        data: page.data.zoneRenewalsDoneListData,
        isFetching: page.data.zoneRenewalsDoneListDataIsFetching,
        isLoading: page.data.zoneRenewalsDoneListDataIsLoading,
    } = useZoneRenewalsDoneListQuery(zoneRenewalsDoneListParams));

    return (
        <div className="builder_wrapper">
            <PMGrid container={true} marginTop={2}>
                <TableWidget
                    header="Branch wise Renewals Data"
                    showSerialNumber={false}
                    showQuickFilter={false}
                    hideFooter={true}
                    xs={12}
                    tableApi={page.data.zoneRenewalsDoneListData || []}
                    tableParams={[page.parameters.renewalListPagination, page.parameters.renewalListPaginationSet]}
                    columns={[
                        { field: "sales_branch", headerName: "Sales Branch", type: "relatedlink" },
                        { field: "sales_head", headerName: "Sales Head" },
                        { field: "total_renewals", headerName: "Total Renewals" },
                        { field: "total_premium", headerName: "Total Premium", type: "currency" },
                        { field: "pending", headerName: "Pending" },
                        { field: "done", headerName: "Done" },
                        { field: "missed", headerName: "Missed" },
                    ]}
                ></TableWidget>
            </PMGrid>
        </div>
    );
}
export default ZoneRenewalsList;
