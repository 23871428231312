// @ts-nocheck

import { FormWidget, PMGrid, StatsCardWidget2, TableWidget } from "lib";
import { useState } from "react";

import { useDispatch } from "react-redux";
import { setStore } from "store/appSlice";
import { default as policyRenewalsAllListFilterFormatter } from "transformations/policyRenewalsAllListFilterFormatter";

import { usePolicyRenewalsListQuery } from "store/apiSlice";

import { usePolicyDoneStatsCardQuery } from "store/apiSlice";
function PolicyRenewalsDone() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const dispatch = useDispatch();
    dispatch(setStore({ currentPageTitle: "" }));
    [page.parameters.renewalListFilters, page.parameters.renewalListFiltersSet] = useState({});
    [page.parameters.renewalListPagination, page.parameters.renewalListPaginationSet] = useState({ size: 50 });
    const policyRenewalsListParams = {
        status: "DONE",
        order_by: "-due_date",
        ...page.parameters.renewalListFilters,
        ...page.parameters.renewalListPagination,
    };
    ({
        data: page.data.policyRenewalsListData,
        isFetching: page.data.policyRenewalsListDataIsFetching,
        isLoading: page.data.policyRenewalsListDataIsLoading,
    } = usePolicyRenewalsListQuery(policyRenewalsListParams));
    const policyDoneStatsCardParams = {};
    ({
        data: page.data.policyStatsCardData,
        isFetching: page.data.policyStatsCardDataIsFetching,
        isLoading: page.data.policyStatsCardDataIsLoading,
    } = usePolicyDoneStatsCardQuery(policyDoneStatsCardParams));

    const filterChange = (data) => {
        setRenewalListFilters(data);
    };

    const setRenewalListFilters = (data) => {
        var parameterValue = data;
        parameterValue = policyRenewalsAllListFilterFormatter(parameterValue);
        page.parameters.renewalListFiltersSet(parameterValue);
    };

    return (
        <div className="builder_wrapper">
            <PMGrid container={true}>
                <StatsCardWidget2
                    direction="row"
                    md={3}
                    type="3"
                    fields={page.data.policyStatsCardData?.intervalDone}
                    
                    statsIconColor="primary"
                ></StatsCardWidget2>

                <StatsCardWidget2
                    direction="row"
                    md={3}
                    type="3"
                    fields={page.data.policyStatsCardData?.productWiseDone}
                    statsIconColor="success"
                ></StatsCardWidget2>

                <StatsCardWidget2
                    direction="row"
                    md={3}
                    type="3"
                    fields={page.data.policyStatsCardData?.totalPremiumDone}
                    statsIconColor="warning"
                ></StatsCardWidget2>
            </PMGrid>

            <PMGrid container={true} marginTop={2}>
                <FormWidget
                    submitButtonLabel="Search"
                    header="Apply Filter"
                    fullWidth="true"
                    Onsubmit={filterChange}
                    fieldsets={[
                        {
                            direction: "row",
                            fields: [
                                { label: "Customer Name", name: "existing_policy_id__customer_name", type: "text", md: 4, required: false },
                                {
                                    label: "Policy No",
                                    name: "existing_policy_number",
                                    type: "text",
                                    md: 4,
                                    required: false,
                                },
                                {
                                    label: "Product Type",
                                    name: "product_type",
                                    type: "select",
                                    md: 4,
                                    options: [
                                        { id: "", label: "---" },
                                        { id: "TWO_WHEELER", label: "Two Wheeler" },
                                        { id: "FOUR_WHEELER", label: "Four Wheeler" },
                                        { id: "CV", label: "Commercial Vehicle" },
                                    ],
                                    required: false,
                                },
                            ],
                        },
                    ]}
                ></FormWidget>
            </PMGrid>

            <PMGrid container={true} marginTop={2}>
                <TableWidget
                    header="Renewals List"
                    showSerialNumber={false}
                    xs={12}
                    tableApi={page.data.policyRenewalsListData || []}
                    tableParams={[page.parameters.renewalListPagination, page.parameters.renewalListPaginationSet]}
                    columns={[
                        { field: "renewal_id", headerName: "Renewal ID" },
                        { field: "due_date", headerName: "Due Date", type: "date" },
                        { field: "existing_policy_number", headerName: "Policy No." },
                        { field: "product_type", headerName: "Product Type", sortable: false },
                        { field: "insurer.name", headerName: "Insurer", sortable: false },
                        { field: "existing_policy.customer_name", headerName: "Customer Name" },
                        { field: "existing_policy.total_premium", headerName: "Total Premium", type: "currency" },
                        {
                            field: "insurer_renewal_notice",
                            headerName: "Insurer RN",
                            sortable: false,
                            type: "download",
                        },
                        { field: "broker_renewal_notice", headerName: "My RN", sortable: false, type: "download" },
                        {
                            field: "status",
                            headerName: "Renewal Status",
                            sortable: false,
                            type: "chip",
                            condition: [
                                { rule: "MISSED", color: "error" },
                                { rule: "PENDING", color: "warning" },
                                { rule: "DONE", color: "success" },
                                { rule: "FAILED", color: "secondary" },
                            ],
                        },
                        { field: "View Details", headerName: "View Details", type: "edit", url: "/renewal" },
                    ]}
                ></TableWidget>
            </PMGrid>
        </div>
    );
}
export default PolicyRenewalsDone;
