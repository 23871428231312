// @ts-nocheck

import { FormWidget, PMGrid } from "lib";
import { useState } from "react";

import { errorSerializer } from "helperFn/errorSerializer";
import { useSnackbar } from "notistack";

import { jsonToFormData } from "helperFn/formData";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePolicyCreateMutation } from "store/apiSlice";
import { setStore } from "store/appSlice";

import { useInsurerFetchQuery } from "store/apiSlice";

import { usePospFetchQuery } from "store/apiSlice";
function PolicyCreate() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const { enqueueSnackbar } = useSnackbar();
    const [policyCreateMutationTrigger, policyCreateMutationResult] = usePolicyCreateMutation();
    const history = useNavigate();
    const dispatch = useDispatch();
    dispatch(setStore({ currentPageTitle: "" }));
    [page.parameters.productTypeData, page.parameters.productTypeDataSet] = useState("");
    [page.parameters.businessTypeData, page.parameters.businessTypeDataSet] = useState("");
    [page.parameters.coverageTypeData, page.parameters.coverageTypeDataSet] = useState("");
    [page.parameters.vehicleClassData, page.parameters.vehicleClassDataSet] = useState("");
    [page.parameters.paymentModeData, page.parameters.paymentModeDataSet] = useState("");
    [page.parameters.policyFormData, page.parameters.policyFormDataSet] = useState({});
    const insurerFetchParams = {};
    ({
        data: page.data.insurerAutocomplete,
        isFetching: page.data.insurerAutocompleteIsFetching,
        isLoading: page.data.insurerAutocompleteIsLoading,
    } = useInsurerFetchQuery(insurerFetchParams));
    const pospFetchParams = {};
    ({
        data: page.data.pospAutocomplete,
        isFetching: page.data.pospAutocompleteIsFetching,
        isLoading: page.data.pospAutocompleteIsLoading,
    } = usePospFetchQuery(pospFetchParams));

    const handleCoverageTypeChange = (data) => {
        toggleCoverageTypeData(data);
    };

    const handleProductTypeChange = (data) => {
        changeProductTypeData(data);
    };

    const leadNavigate = (data) => {
        history("/policy-list");
    };

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Policy Updated Successfully.", { variant: "success" });
    };

    const policyCreate = async (data) => {
        var apiData = data;

        if (apiData) {
            policyCreateMutationTrigger(
                jsonToFormData({
                    product_type: apiData["product_type"],
                    customer_name: apiData["customer_name"],
                    customer_phone: apiData["customer_phone"],
                    business_type: apiData["business_type"],
                    customer_email: apiData["customer_email"],
                    customer_address: apiData["customer_address"],
                    customer_city: apiData["customer_city"],
                    rto_state: apiData["rto_state"],
                    customer_pincode: apiData["customer_pincode"],
                    vehicle_class: apiData["vehicle_class"],
                    vehicle_subclass: apiData["vehicle_subclass"],
                    make: apiData["make"],
                    model: apiData["model"],
                    posp_id: apiData["posp_id"],
                    variant: apiData["variant"],
                    chasis_number: apiData["chasis_number"],
                    engine_number: apiData["engine_number"],
                    registration_number: apiData["registration_number"],
                    manufacturing_year: apiData["manufacturing_year"],
                    registration_date: apiData["registration_date"],
                    rto_city: apiData["rto_city"],
                    coverage_type: apiData["coverage_type"],
                    policy_number: apiData["policy_number"],
                    start_date: apiData["start_date"],
                    end_date: apiData["end_date"],
                    od_start_date: apiData["od_start_date"],
                    od_end_date: apiData["od_end_date"],
                    policy_document: apiData["policy_document"],
                    tp_start_date: apiData["tp_start_date"],
                    tp_end_date: apiData["tp_end_date"],
                    insurer_id: apiData["insurer_id"],
                    vehicle_idv: apiData["vehicle_idv"],
                    sum_insured: apiData["sum_insured"],
                    broker_branch_code: apiData["broker_branch_code"],
                    policy_status: apiData["policy_status"],
                    issue_date: apiData["issue_date"],
                    previous_policy_number: apiData["previous_policy_number"],
                    previous_insurer_name: apiData["previous_insurer_name"],
                    previous_policy_end_date: apiData["previous_policy_end_date"],
                    basic_od_premium: apiData["basic_od_premium"],
                    basic_tp_premium: apiData["basic_tp_premium"],
                    ncb: apiData["ncb"],
                    total_od_premium: apiData["total_od_premium"],
                    total_tp_premium: apiData["total_tp_premium"],
                    net_premium: apiData["net_premium"],
                    service_tax: apiData["service_tax"],
                    total_premium: apiData["total_premium"],
                    policy_type: apiData["policy_type"],
                    payment_mode: apiData["payment_mode"],
                    payment_reference_number: apiData["payment_reference_number"],
                    payment_method: apiData["payment_method"],
                    additional_info: apiData["additional_info"],
                    payment_document: apiData["payment_document"],
                    payment_status: apiData["payment_status"],
                    imt_34_cover: apiData["imt_34_cover"],
                    pa_cover_premium: apiData["pa_cover_premium"],
                    zero_dep_premium: apiData["zero_dep_premium"],
                    engine_cover_premium: apiData["engine_cover_premium"],
                    roadside_premium: apiData["roadside_premium"],
                })
            )
                .unwrap()
                .then((data) => {
                    showSuccessAlert(data);
                    leadNavigate(data);
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(errorSerializer(error), { variant: "error" });
                });
        }
    };

    const policyCreateApi = (data) => {
        policyCreate(data);
    };

    const changeProductTypeData = (data) => {
        var parameterValue = data;

        page.parameters.productTypeDataSet(parameterValue);
    };

    const toggleCoverageTypeData = (data) => {
        var parameterValue = data;

        page.parameters.coverageTypeDataSet(parameterValue);
    };

    return (
        <div className="builder_wrapper">
            <PMGrid container={true} xs={12}>
                <FormWidget
                    direction="column"
                    header="Create New Policy"
                    defaultValues={page.parameters.policyFormData}
                    Onsubmit={policyCreateApi}
                    fieldsets={[
                        {
                            direction: "row",
                            header: "Primary Details",
                            fields: [
                                {
                                    label: "Product Type",
                                    name: "product_type",
                                    type: "select",
                                    required: true,
                                    onChange: handleProductTypeChange,
                                    options: [
                                        { id: "TWO_WHEELER", label: "Two Wheeler" },
                                        { id: "FOUR_WHEELER", label: "Four Wheeler" },
                                        { id: "CV", label: "CV" },
                                    ],
                                },
                                {
                                    label: "POSP Code",
                                    name: "posp_id",
                                    type: "autocomplete",
                                    options: page.data.pospAutocomplete?.items || [],
                                },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Customer Details",
                            fields: [
                                { label: "Customer Name", name: "customer_name", required: true, type: "text" ,validation: { min: 0, pattern: "^\[A-Za-z][A-Za-z\\s]+$" },},
                                { label: "Mobile No", name: "customer_phone", type: "text",
                                validation: {  pattern: "^\[6-9][0-9]{9}$" }  },
                                { label: "Email", name: "customer_email", type: "text" },
                            ],
                        },
                        {
                            direction: "row",
                            fields: [
                                { label: "Address", name: "customer_address", md:8, required: true, type: "text" },
                                { label: "Pincode", required: true, md: 4, name: "customer_pincode", type: "text",
                                validation: { pattern: "^\[1-9][0-9]{5}$" } },
                            ],
                        },
                        {
                            direction: "row",
                            fields: [
                            ],
                        },
                        {
                            direction: "row",
                            header: "Vehicle Details",
                            grid: 3,
                            fields: [
                                { label: "Make", required: true, name: "make", type: "text",validation: { min: 0, pattern: "^\[A-Za-z][A-Za-z.&-()\\s]+$"  },},
                                { label: "Model", required: true, name: "model", type: "text" },
                                { label: "Variant", name: "variant", type: "text" },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            fields: [
                                { label: "Chassis Number", name: "chasis_number", type: "text" },
                                { label: "Engine Number", name: "engine_number", type: "text" },
                                {
                                    label: "Manufacturing Year",
                                    md: 4,
                                    required: true,
                                    name: "manufacturing_year",
                                    type: "text",
                                },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            fields: [
                                {
                                    label: "Registration Number",
                                    name: "registration_number",
                                    type: "text",
                                },
                                {
                                    label: "Registration Date",
                                    name: "registration_date",
                                    type: "date",
                                },
                                { label: "RTO City",  name: "rto_city", type: "text" },
                                { label: "RTO State", name: "rto_state", type: "text" },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Policy Details",
                            grid: 3,
                            fields: [
                                {
                                    label: "Insurer",
                                    name: "insurer_id",
                                    type: "select",
                                    md: 4,
                                    required: true,
                                    options: page.data.insurerAutocomplete?.items || [],
                                },
                                {
                                    label: "Coverage Type",
                                    name: "coverage_type",
                                    type: "select",
                                    md:4,
                                    required: true,
                                    onChange: handleCoverageTypeChange,
                                    options: [
                                        { id: "SAOD", label: "SAOD" },
                                        { id: "LIABILITY", label: "LIABILITY" },
                                        { id: "PACKAGE", label: "PACKAGE" },
                                    ],
                                },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            displayon: page.parameters.coverageTypeData == "LIABILITY",
                            fields: [
                                { label: "Policy Number",md:4, name: "policy_number", type: "text", required: true },
                                {
                                    label: "Total IDV",
                                    name: "sum_insured",
                                    type: "text",
                                    prefix: "₹",
                                    md: 4,
                                    displayon: page.parameters.coverageTypeData == "LIABILITY",
                                    required: page.parameters.coverageTypeData != "LIABILITY",
                                    validation: { min: 0, pattern: "^\\d+$" },
                                },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            fields: [
                                { label: "Policy Issue Date", name: "issue_date", required: true, type: "date" },
                                { label: "Policy Start Date", name: "start_date", required: true, type: "date" },
                                { label: "Policy End Date", name: "end_date", required: true, type: "date" },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            header: "Own Damage Dates",
                            displayon: page.parameters.coverageTypeData == "LIABILITY",
                            fields: [
                                {
                                    label: "Own Damage Start Date",
                                    name: "od_start_date",
                                    type: "date",
                                    displayon: page.parameters.coverageTypeData == "LIABILITY",
                                    required: page.parameters.coverageTypeData != "LIABILITY",
                                },
                                {
                                    label: "Own Damage End Date",
                                    name: "od_end_date",
                                    type: "date",
                                    displayon: page.parameters.coverageTypeData == "LIABILITY",
                                    required: page.parameters.coverageTypeData != "LIABILITY",
                                },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            header: "Liability/Third Party Dates",
                            displayon: page.parameters.coverageTypeData == "SAOD",
                            fields: [
                                {
                                    label: "TP Start Date",
                                    name: "tp_start_date",
                                    type: "date",
                                    displayon: page.parameters.coverageTypeData == "SAOD",
                                    required: page.parameters.coverageTypeData != "SAOD",
                                },
                                {
                                    label: "TP End Date",
                                    name: "tp_end_date",
                                    type: "date",
                                    displayon: page.parameters.coverageTypeData == "SAOD",
                                    required: page.parameters.coverageTypeData != "SAOD",
                                },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            header: "Policy Document",
                            fields: [
                                { label: "Policy Document", name: "policy_document",md:6, type: "file", required: true },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            fields: [
                                {
                                    label: "Policy Status",
                                    name: "policy_status",
                                    type: "select",
                                    md: 6,
                                    options: [
                                        { id: "PENDING", label: "Pending" },
                                        { id: "DONE", label: "Success" },
                                        { id: "CANCELLED", label: "Cancelled" },
                                    ],
                                },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Previous Policy Details",
                            grid: 3,
                            fields: [
                                { label: "Previous Policy Number", name: "previous_policy_number", type: "text" },
                                {
                                    label: "Previous Insurer",
                                    name: "previous_insurer_name",
                                    type: "text"
                                },
                                { label: "Previous Policy End Date", name: "previous_policy_end_date", type: "date" },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Own DamagePremium Details",
                            grid: 3,
                            displayon: page.parameters.coverageTypeData == "LIABILITy",
                            fields: [
                                {
                                    label: "Basic Own Damage Premium",
                                    name: "basic_od_premium",
                                    type: "text",
                                    prefix: "₹",
                                    displayon: page.parameters.coverageTypeData == "LIABILITY",
                                    required: page.parameters.coverageTypeData != "LIABILITY",
                                    validation: { min: 0, pattern: "^\\d+$" },
                                },
                                {
                                    label: "NCB",
                                    name: "ncb",
                                    type: "select",
                                    options: [
                                        { id: "0", label: "0 %" },
                                        { id: "20", label: "20 %" },
                                        { id: "25", label: "25 %" },
                                        { id: "35", label: "35 %" },
                                        { id: "45", label: "45 %" },
                                        { id: "50", label: "50 %" },
                                    ],
                                    displayon: page.parameters.coverageTypeData == "LIABILITY",
                                    required: page.parameters.coverageTypeData != "LIABILITY",
                                },
                                {
                                    label: "Total Own Damage Premium",
                                    name: "total_od_premium",
                                    type: "text",
                                    prefix: "₹",
                                    validation: { min: 0, pattern: "^\\d+$" },
                                    displayon: page.parameters.coverageTypeData == "LIABILITY",
                                    required: page.parameters.coverageTypeData != "LIABILITY",
                                },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            header: "Liability Premium Details",
                            displayon: page.parameters.coverageTypeData == "SAOD",
                            fields: [
                                {
                                    label: "Basic TP Premium",
                                    name: "basic_tp_premium",
                                    type: "text",
                                    prefix: "₹",
                                    displayon: page.parameters.coverageTypeData == "SAOD",
                                    required: page.parameters.coverageTypeData != "SAOD",
                                    validation: { min: 0, pattern: "^\\d+$" },
                                },
                                {
                                    label: "Total TP Premium",
                                    name: "total_tp_premium",
                                    type: "text",
                                    prefix: "₹",
                                    validation: { min: 0, pattern: "^\\d+$" },
                                    displayon: page.parameters.coverageTypeData == "SAOD",
                                    required: page.parameters.coverageTypeData != "SAOD",
                                },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Addons",
                            grid: 3,
                            fields: [
                                {
                                    label: "RSA Premium",
                                    name: "roadside_premium",
                                    md: 4,
                                    type: "text",
                                    prefix: "₹",
                                    validation: { min: 0, pattern: "^\\d+$" },
                                },
                                {
                                    label: "Zero Dep Premium",
                                    name: "zero_dep_premium",
                                    md: 4,
                                    type: "text",
                                    prefix: "₹",
                                    validation: { min: 0, pattern: "^\\d+$" },
                                },
                                {
                                    label: "Engine Cover Premium",
                                    name: "engine_cover_premium",
                                    md: 4,
                                    type: "text",
                                    prefix: "₹",
                                    validation: { min: 0, pattern: "^\\d+$" },
                                },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            fields: [
                                {
                                    label: "PA Cover Premium",
                                    name: "pa_cover_premium",
                                    md: 4,
                                    type: "text",
                                    prefix: "₹",
                                    validation: { min: 0, pattern: "^\\d+$" },
                                },
                                {
                                    label: "IMT 34 Premium",
                                    name: "imt_34_cover",
                                    md: 4,
                                    type: "text",
                                    prefix: "₹",
                                    validation: { min: 0, pattern: "^\\d+$" },
                                },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            header: "Total Premium Details",
                            fields: [
                                {
                                    label: "Net Premium",
                                    name: "net_premium",
                                    type: "text",
                                    required: true,
                                    prefix: "₹",
                                    validation: { min: 0, pattern: "^\\d+$" },
                                },
                                {
                                    label: "Taxes",
                                    name: "service_tax",
                                    type: "text",
                                    prefix: "₹",
                                    required: true,
                                    validation: { min: 0, pattern: "^\\d+$" },
                                },
                                {
                                    label: "Total Premium",
                                    name: "total_premium",
                                    type: "text",
                                    prefix: "₹",
                                    required: true,
                                    validation: { min: 0, pattern: "^\\d+$" },
                                },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Payment Details",
                            grid: 3,
                            fields: [
                                {
                                    label: "Payment Mode",
                                    name: "payment_mode",
                                    type: "select",
                                    options: [
                                        { id: "ONLINE", label: "Online" },
                                        { id: "OFFLINE", label: "Offline" },
                                    ],
                                },
                                {
                                    label: "Payment Reference Number",
                                    name: "payment_reference_number",
                                    type: "text",
                                },
                                {
                                    label: "Payment Method",
                                    name: "payment_method",
                                    type: "select",
                                    options: [
                                        { id: "CHEQUE", label: "Cheque" },
                                        { id: "DD", label: "DD" },
                                        { id: "UPI", label: "UPI" },
                                        { id: "NET_BANKING", label: "Net Banking" },
                                        { id: "CREDIT_CARD", label: "Credit Card" },
                                        { id: "DEBIT_CARD", label: "Debit Card" },
                                    ],
                                },
                            ],
                        },
                        {
                            direction: "row",
                            grid: 3,
                            fields: [
                                { label: "Additional Info", name: "additional_info", type: "text" },
                                {
                                    label: "Payment Status",
                                    name: "payment_status",
                                    type: "select",
                                    // required: true,
                                    options: [
                                        { id: "PENDING", label: "Pending" },
                                        { id: "SUCCESS", label: "Success" },
                                        { id: "FAILED", label: "Failed" },
                                    ],
                                },
                            ],
                        },
                    ]}
                ></FormWidget>
            </PMGrid>
        </div>
    );
}
export default PolicyCreate;
