import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { default as transformPolicyAllStatsCard } from "transformations/transformPolicyAllStatsCard";
import { default as transformPolicyDoneStatsCard } from "transformations/transformPolicyDoneStatsCard";
import { default as transformPolicyMissedStatsCard } from "transformations/transformPolicyMissedStatsCard";
import { default as transformPolicyPendingStatsCard } from "transformations/transformPolicyPendingStatsCard";

// Create baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: "",
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      headers.set("authorization", "Bearer " + token);
    }
    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQuery,
  tagTypes: [
    "loginApiTag",
    "signupApiTag",
    "forgotPasswordTag",
    "resetPasswordTag",
    "uploadPolicyTag",
    "parsedPolicyListTag",
    "parsedPolicyDetailsTag",
    "parsedPolicyUpdateTag",
    "kycApiTag",
    "renewalThisMonthTag",
    "upcomingRenewalSevenDaysTag",
    "todayActivityRenewalsTag",
    "policyRenewalsPendingListTag",
    "policyRenewalsDoneListTag",
    "policyRenewalsMissedListTag",
    "markAsFailedTag",
    "policyRenewalsListTag",
    "pospFetchTag",
    "employeeFetchTag",
    "insurerFetchTag",
    "policyListTag",
    "policyAllStatsCardTag",
    "policyMissedStatsCardTag",
    "policyDoneStatsCardTag",
    "policyOfThisMonthTag",
    "policyPendingStatsCardTag",
    "renewalPolicyDetailTag",
    "policyDetailsTag",
    "policyRenewalsListFilterTag",
    "renewalDetailsTag",
    "renewalDetailFollowupTag",
    "policyDetailTag",
    "pospListAutocompleteTag",
    "pospListTag",
    "pospDetailTag",
    "followupListTag",
    "policyListFilterTag",
    "zoneRenewalsListTag",
    "zoneRenewalsDoneListTag",
    "branchRenewalsDoneListTag",
    "rmRenewalsDoneListTag",
    "renewalStatsPospListTag",
    "employeeListAutocompleteTag",
    "pospKycListTag",
    "addonFetchTag",
    "verifyAdharTag",
    "rejectAdharTag",
    "verifyPanTag",
    "rejectPanTag",
    "employeeListTag",
    "employeeCreateTag",
    "policyCreateTag",
    "policyUpdateTag",
    "employeeUpdateTag",
    "employeeDetailTag",
    "pospDirectRenewalTag",
    "pospDetailStatsTag",
    "pospDashboardRenewalStatsTag",
    "pospDetailFilterTag",
    "interactionCreateTag",
    "renewalInteractionHistoryTag",
    "policyRenewalsIntervalStatsTag",
    "monthlyRenewalsChangeTag",
    "payinRulesTag",
  ],
  endpoints: (builder) => ({
    loginApi: builder.mutation({
      query: (data) => ({
        url: "https://brand-logistics.demo.permute.in/auth/api/v1/login",
        method: "POST",
        body: data,
      }),
    }),
    signupApi: builder.mutation({
      query: (data) => ({
        url: "https://brand-logistics.demo.permute.in/auth/api/v1/signup",
        method: "POST",
        body: data,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: "https://brand-logistics.demo.permute.in/auth/api/v1/reset-password/request",
        method: "POST",
        body: data,
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: "https://brand-logistics.demo.permute.in/auth/api/v1/reset-password",
        method: "POST",
        body: data,
      }),
    }),
    uploadPolicy: builder.mutation({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/request-parsing",
        method: "POST",
        body: data,
      }),
    }),
    parsedPolicyList: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["parsedPolicyListTag"],
    }),
    parsedPolicyDetails: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/detail",
        params: data,
        method: "GET",
      }),
      providesTags: ["parsedPolicyDetailsTag"],
    }),
    parsedPolicyUpdate: builder.mutation({
      query: ({ id, data }) => ({
        url:
          "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/update?id=" +
          id,
        method: "PATCH",
        body: data,
      }),
    }),
    kycApi: builder.mutation({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/kycverification/create",
        method: "POST",
        body: data,
      }),
    }),
    renewalThisMonth: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/renewal-stats",
        params: data,
        method: "GET",
      }),
      providesTags: ["renewalThisMonthTag"],
    }),
    upcomingRenewalSevenDays: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/renewal-stats",
        params: data,
        method: "GET",
      }),
      providesTags: ["upcomingRenewalSevenDaysTag"],
    }),
    todayActivityRenewals: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/renewal-stats",
        params: data,
        method: "GET",
      }),
      providesTags: ["todayActivityRenewalsTag"],
    }),
    policyRenewalsPendingList: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyRenewalsPendingListTag"],
    }),
    policyRenewalsDoneList: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyRenewalsDoneListTag"],
    }),
    policyRenewalsMissedList: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyRenewalsMissedListTag"],
    }),
    markAsFailed: builder.mutation({
      query: ({ id, data }) => ({
        url:
          "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/update?id=" +
          id,
        method: "PATCH",
        body: data,
      }),
    }),
    policyRenewalsList: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyRenewalsListTag"],
    }),
    pospFetch: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/posp/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["pospFetchTag"],
    }),
    employeeFetch: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/employee/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["employeeFetchTag"],
    }),
    insurerFetch: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/insurer/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["insurerFetchTag"],
    }),
    policyList: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyListTag"],
    }),
    policyAllStatsCard: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/stats-card/all",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyAllStatsCardTag"],

      transformResponse: (response, meta, arg) =>
        transformPolicyAllStatsCard(response),
    }),
    policyMissedStatsCard: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/stats-card/missed",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyMissedStatsCardTag"],

      transformResponse: (response, meta, arg) =>
        transformPolicyMissedStatsCard(response),
    }),
    policyDoneStatsCard: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/stats-card/done",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyDoneStatsCardTag"],

      transformResponse: (response, meta, arg) =>
        transformPolicyDoneStatsCard(response),
    }),
    policyOfThisMonth: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/aggregate",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyOfThisMonthTag"],
    }),
    policyPendingStatsCard: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/stats-card/pending",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyPendingStatsCardTag"],

      transformResponse: (response, meta, arg) =>
        transformPolicyPendingStatsCard(response),
    }),
    renewalPolicyDetail: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["renewalPolicyDetailTag"],
    }),
    policyDetails: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/detail",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyDetailsTag"],
    }),
    policyRenewalsListFilter: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyRenewalsListFilterTag"],
    }),
    renewalDetails: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/detail",
        params: data,
        method: "GET",
      }),
      providesTags: ["renewalDetailsTag"],
    }),
    renewalDetailFollowup: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/detail",
        params: data,
        method: "GET",
      }),
      providesTags: ["renewalDetailFollowupTag"],
    }),
    policyDetail: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyDetailTag"],
    }),
    pospListAutocomplete: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/posp/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["pospListAutocompleteTag"],
    }),
    pospList: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/posp/interval-filter",
        params: data,
        method: "GET",
      }),
      providesTags: ["pospListTag"],
    }),
    pospDetail: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/posp/detail",
        params: data,
        method: "GET",
      }),
      providesTags: ["pospDetailTag"],
    }),
    followupList: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/followup",
        params: data,
        method: "GET",
      }),
      providesTags: ["followupListTag"],
    }),
    policyListFilter: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyListFilterTag"],
    }),
    zoneRenewalsList: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/policy-aggregate",
        params: data,
        method: "GET",
      }),
      providesTags: ["zoneRenewalsListTag"],
    }),
    zoneRenewalsDoneList: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/policy-aggregate",
        params: data,
        method: "GET",
      }),
      providesTags: ["zoneRenewalsDoneListTag"],
    }),
    branchRenewalsDoneList: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/policy-aggregate",
        params: data,
        method: "GET",
      }),
      providesTags: ["branchRenewalsDoneListTag"],
    }),
    rmRenewalsDoneList: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["rmRenewalsDoneListTag"],
    }),
    renewalStatsPospList: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/renewal-stats",
        params: data,
        method: "GET",
      }),
      providesTags: ["renewalStatsPospListTag"],
    }),
    employeeListAutocomplete: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/employee/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["employeeListAutocompleteTag"],
    }),
    pospKycList: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/kycverification/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["pospKycListTag"],
    }),
    addonFetch: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/addonmaster/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["addonFetchTag"],
    }),
    verifyAdhar: builder.mutation({
      query: ({ id, data }) => ({
        url:
          "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/kycverification/verify?id=" +
          id,
        method: "PATCH",
        body: data,
      }),
    }),
    rejectAdhar: builder.mutation({
      query: ({ id, data }) => ({
        url:
          "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/kycverification/reject?id=" +
          id,
        method: "PATCH",
        body: data,
      }),
    }),
    verifyPan: builder.mutation({
      query: ({ id, data }) => ({
        url:
          "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/kycverification/verify?id=" +
          id,
        method: "PATCH",
        body: data,
      }),
    }),
    rejectPan: builder.mutation({
      query: ({ id, data }) => ({
        url:
          "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/kycverification/reject?id=" +
          id,
        method: "PATCH",
        body: data,
      }),
    }),
    employeeList: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/employee/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["employeeListTag"],
    }),
    employeeCreate: builder.mutation({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/employee/create",
        method: "POST",
        body: data,
      }),
    }),
    policyCreate: builder.mutation({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/create",
        method: "POST",
        body: data,
      }),
    }),
    policyUpdate: builder.mutation({
      query: ({ id, data }) => ({
        url:
          "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/policy/update?id=" +
          id,
        method: "PATCH",
        body: data,
      }),
    }),
    employeeUpdate: builder.mutation({
      query: ({ id, data }) => ({
        url:
          "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/employee/update?id=" +
          id,
        method: "PATCH",
        body: data,
      }),
    }),
    employeeDetail: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/employee/detail",
        params: data,
        method: "GET",
      }),
      providesTags: ["employeeDetailTag"],
    }),
    pospDirectRenewal: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/posp-direct",
        params: data,
        method: "GET",
      }),
      providesTags: ["pospDirectRenewalTag"],
    }),
    pospDetailStats: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/posp/posp-detail-stats",
        params: data,
        method: "GET",
      }),
      providesTags: ["pospDetailStatsTag"],
    }),
    pospDashboardRenewalStats: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/posp-dashboard-stats",
        params: data,
        method: "GET",
      }),
      providesTags: ["pospDashboardRenewalStatsTag"],
    }),
    pospDetailFilter: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["pospDetailFilterTag"],
    }),
    interactionCreate: builder.mutation({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/interaction/create",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["renewalInteractionHistoryTag"],
    }),
    renewalInteractionHistory: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/interaction/list",
        params: data,
        method: "GET",
      }),
      providesTags: ["renewalInteractionHistoryTag"],
    }),
    policyRenewalsIntervalStats: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/interval-aggregate",
        params: data,
        method: "GET",
      }),
      providesTags: ["policyRenewalsIntervalStatsTag"],
    }),
    monthlyRenewalsChange: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/renewal/monthly-change",
        params: data,
        method: "GET",
      }),
      providesTags: ["monthlyRenewalsChangeTag"],
    }),
    payinRules: builder.query({
      query: (data) => ({
        url: "https://trial-policy-management.demo.permute.in/policy_renewal/api/v1/payin/rule/get",
        params: data,
        method: "GET",
      }),
      providesTags: ["payinRulesTag"],
    }),
  }),
});

export const {
  useLoginApiMutation,
  useSignupApiMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useUploadPolicyMutation,
  useParsedPolicyListQuery,
  useParsedPolicyDetailsQuery,
  useParsedPolicyUpdateMutation,
  useKycApiMutation,
  useRenewalThisMonthQuery,
  useUpcomingRenewalSevenDaysQuery,
  useTodayActivityRenewalsQuery,
  usePolicyRenewalsPendingListQuery,
  usePolicyRenewalsDoneListQuery,
  usePolicyRenewalsMissedListQuery,
  useMarkAsFailedMutation,
  usePolicyRenewalsListQuery,
  usePospFetchQuery,
  useEmployeeFetchQuery,
  useInsurerFetchQuery,
  usePolicyListQuery,
  usePolicyAllStatsCardQuery,
  usePolicyMissedStatsCardQuery,
  usePolicyDoneStatsCardQuery,
  usePolicyOfThisMonthQuery,
  usePolicyPendingStatsCardQuery,
  useRenewalPolicyDetailQuery,
  usePolicyDetailsQuery,
  usePolicyRenewalsListFilterQuery,
  useRenewalDetailsQuery,
  useRenewalDetailFollowupQuery,
  usePolicyDetailQuery,
  usePospListAutocompleteQuery,
  usePospListQuery,
  usePospDetailQuery,
  useFollowupListQuery,
  usePolicyListFilterQuery,
  useZoneRenewalsListQuery,
  useZoneRenewalsDoneListQuery,
  useBranchRenewalsDoneListQuery,
  useRmRenewalsDoneListQuery,
  useRenewalStatsPospListQuery,
  useEmployeeListAutocompleteQuery,
  usePospKycListQuery,
  useAddonFetchQuery,
  useVerifyAdharMutation,
  useRejectAdharMutation,
  useVerifyPanMutation,
  useRejectPanMutation,
  useEmployeeListQuery,
  useEmployeeCreateMutation,
  usePolicyCreateMutation,
  usePolicyUpdateMutation,
  useEmployeeUpdateMutation,
  useEmployeeDetailQuery,
  usePospDirectRenewalQuery,
  usePospDetailStatsQuery,
  usePospDashboardRenewalStatsQuery,
  usePospDetailFilterQuery,
  useInteractionCreateMutation,
  useRenewalInteractionHistoryQuery,
  usePolicyRenewalsIntervalStatsQuery,
  useMonthlyRenewalsChangeQuery,
  usePayinRulesQuery,
} = apiSlice;
