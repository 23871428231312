// @ts-nocheck

import { FormWidget, NotesWidget, PMGrid } from "lib";

import { errorSerializer } from "helperFn/errorSerializer";
import { useSnackbar } from "notistack";

import { jsonToFormData } from "helperFn/formData";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useUploadPolicyMutation } from "store/apiSlice";
import { setStore } from "store/appSlice";
function UploadPolicy() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const { enqueueSnackbar } = useSnackbar();
    const [uploadPolicyMutationTrigger, uploadPolicyMutationResult] = useUploadPolicyMutation();
    const history = useNavigate();
    const dispatch = useDispatch();
    dispatch(setStore({ currentPageTitle: "" }));

    const policyCreateNav = (data) => {
        history("/policy-edit" + "/" + data["id"]);
    };

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Policy Created", { variant: "success" });
    };

    const uploadPolicy = async (data) => {
        var apiData = data;

        if (apiData) {
            uploadPolicyMutationTrigger(jsonToFormData({ file: apiData["file"] }))
                .unwrap()
                .then((data) => {
                    showSuccessAlert(data);
                    policyCreateNav(data);
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(errorSerializer(error), { variant: "error" });
                });
        }
    };

    const uploadPolicyApi = (data) => {
        uploadPolicy(data);
    };

    return (
        <div className="builder_wrapper">
            <PMGrid container={true} xs={12}>
                <FormWidget
                    xs={6}
                    direction="row"
                    isLoading={uploadPolicyMutationTrigger[1]?.isLoading}
                    header="Upload Policy PDF for extraction"
                    
                    submitButtonLabel="Extract"
                    Onsubmit={uploadPolicyApi}
                    fieldsets={[
                        {
                            direction: "row",
                            fields: [{ label: "Upload Policy File",md:6, name: "file", type: "file", required: true }],
                        },
                    ]}
                ></FormWidget>
                <NotesWidget 
                    direction="column"
                    columns={1}
                    rowSpacing={1}
                    textColor="secondary"
                    header="Please Note"
                    md={12}
                    fields={[
                        {
                            label: "- The following system is a demo environment and is currently only configured for 'Go Digit' and 'Reliance' and only caters to 'Motor Insurance' policies. ",
                            value: "",
                        },
                        { label: "- Kindly ensure that you upload Two Wheeler, Four Wheeler, and Commercial Vehicle(CV) poicies exclusively from the two specified insurers.", value:"" },
                    ]}>

                </NotesWidget>
            </PMGrid>
        </div>
    );
}
export default UploadPolicy;
