// @ts-nocheck

import { PMGrid, TableWidget } from "lib";
import { useState } from "react";

import { useDispatch } from "react-redux";
import { setStore } from "store/appSlice";

import { usePayinRulesQuery } from "store/apiSlice";
function MotorRulesMaster() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const dispatch = useDispatch();
    dispatch(setStore({ currentPageTitle: "" }));
    [page.parameters.rulesListPagination, page.parameters.rulesListPaginationSet] = useState({});
    const payinRulesParams = {};
    ({
        data: page.data.rulesList,
        isFetching: page.data.rulesListIsFetching,
        isLoading: page.data.rulesListIsLoading,
    } = usePayinRulesQuery(payinRulesParams));

    return (
        <div className="builder_wrapper">
            <PMGrid container={true}>
                <TableWidget
                    header="PayIn Rules"
                    xs={12}
                    tableApi={page.data.rulesList || []}
                    tableParams={[page.parameters.rulesListPagination, page.parameters.rulesListPaginationSet]}
                    columns={[
                        { field: "vehicle_type", headerName: "Vehicle Type" },
                        { field: "policy_type", headerName: "Policy Type" },
                        { field: "multiplier", headerName: "Multiplier Metric" },
                        { field: "multiplier_type", headerName: "Multiplier Type" },
                        { field: "multiplier_value", headerName: "Multiplier Value" },
                    ]}
                ></TableWidget>
            </PMGrid>
        </div>
    );
}
export default MotorRulesMaster;
