const renewalDetailsFormatter = (data, dataKey) => {

  const dataSet = JSON.parse(JSON.stringify(data?.items||data));
  if(dataSet?.length){
    return dataSet[0]
  }
  return dataSet;
};

  export default renewalDetailsFormatter;
  