// @ts-nocheck

import { MetricWidget2, PMGrid, PMText } from "lib";

import { useDispatch } from "react-redux";
import { setStore } from "store/appSlice";

import { useRenewalThisMonthQuery } from "store/apiSlice";

import { useUpcomingRenewalSevenDaysQuery } from "store/apiSlice";

function PolicyRenewalsDashboard() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const dispatch = useDispatch();
    dispatch(setStore({ currentPageTitle: "" }));
    const renewalThisMonthParams = { metric: "status",duration_metric: "this_month" };
    ({
        data: page.data.renewalsOfThisMonth,
        isFetching: page.data.renewalsOfThisMonthIsFetching,
        isLoading: page.data.renewalsOfThisMonthIsLoading,
    } = useRenewalThisMonthQuery(renewalThisMonthParams));
    const upcomingRenewalSevenDaysParams = { metric: "product_type", duration_metric: "upcoming_seven_days" };
    ({
        data: page.data.upcomingRenewalSevenDaysData,
        isFetching: page.data.upcomingRenewalSevenDaysDataIsFetching,
        isLoading: page.data.upcomingRenewalSevenDaysDataIsLoading,
    } = useUpcomingRenewalSevenDaysQuery(upcomingRenewalSevenDaysParams));
    const todayActivityRenewalsParams = { metric: "followup" };
   
    return (
        <div className="builder_wrapper">
            <PMGrid>
                <PMText children="Renewals - This Month" variant="h6"></PMText>
            </PMGrid>

            <PMGrid container={true} marginTop={2}>
                <MetricWidget2
                    xs={3}
                    metricValueColor="primary"
                    metricIconColor="primary"
                    type="2"
                    fields={[
                        { label: "Total Renewals Due", value: page.data.renewalsOfThisMonth?.TOTAL?.COUNT||0 },
                        {
                            value: page.data.renewalsOfThisMonth?.TOTAL?.SUM||0,
                            label: "Total Premium Due",
                            type: "currency",
                        },
                        {
                            value: page.data.renewalsOfThisMonth?.TOTAL?.COUNT_PERCENTAGE||0,
                            type: "percentage",
                            label: "",
                        },
                    ]}
                ></MetricWidget2>

                <MetricWidget2
                    xs={3}
                    metricValueColor="success.main"
                    metricIconColor="success"
                    type="3"
                    fields={[
                        { label: "Done", value: page.data.renewalsOfThisMonth?.DONE?.COUNT||0 },
                        {
                            value: page.data.renewalsOfThisMonth?.DONE?.SUM||0,
                            label: "Total Premium Due",
                            type: "currency",
                        },
                        { value: page.data.renewalsOfThisMonth?.DONE?.COUNT_PERCENTAGE||0, type: "percentage", label: "" },
                    ]}
                ></MetricWidget2>

                <MetricWidget2
                    xs={3}
                    metricValueColor="warning.main"
                    metricIconColor="warning"
                    type="3"
                    fields={[
                        { label: "Pending", value: page.data.renewalsOfThisMonth?.PENDING?.COUNT||0 },
                        {
                            value: page.data.renewalsOfThisMonth?.PENDING?.SUM||0,
                            label: "Total Premium Due",
                            type: "currency",
                        },
                        {
                            value: page.data.renewalsOfThisMonth?.PENDING?.COUNT_PERCENTAGE||0,
                            type: "percentage",
                            label: "",
                        },
                    ]}
                ></MetricWidget2>

                <MetricWidget2
                    xs={3}
                    metricValueColor="error.main"
                    metricIconColor="error"
                    type="3"
                    fields={[
                        { label: "Missed", value: page.data.renewalsOfThisMonth?.MISSED?.COUNT||0 },
                        {
                            value: page.data.renewalsOfThisMonth?.MISSED?.SUM||0,
                            label: "Total Premium Due",
                            type: "currency",
                        },
                        {
                            value: page.data.renewalsOfThisMonth?.MISSED?.COUNT_PERCENTAGE||0,
                            type: "percentage",
                            label: "",
                        },
                    ]}
                ></MetricWidget2>
            </PMGrid>

            {/* <PMGrid marginTop={4}>
                <PMText children="Today's Activity" variant="h6"></PMText>
            </PMGrid>

            <PMGrid container={true} marginTop={2}>
                <MetricWidget2
                    xs={3}
                    metricValueColor="primary"
                    metricIconColor="primary"
                    type="3"
                    fields={[
                        { label: "Commercial Vehicle", value: page.data.renewalsOfThisMonth?.CV?.COUNT },
                        { value: page.data.renewalsOfThisMonth?.CV?.SUM, label: "Total Premium Due", type: "currency" },
                        { value: page.data.renewalsOfThisMonth?.CV?.COUNT_PERCENTAGE, type: "percentage", label: "" },
                    ]}
                ></MetricWidget2>

                <MetricWidget2
                    xs={3}
                    metricValueColor="success.main"
                    metricIconColor="success"
                    type="3"
                    fields={[
                        { label: "Four Wheeler", value: page.data.renewalsOfThisMonth?.FOUR_WHEELER?.COUNT },
                        {
                            value: page.data.renewalsOfThisMonth?.FOUR_WHEELER?.SUM,
                            label: "Total Premium Due",
                            type: "currency",
                        },
                        {
                            value: page.data.renewalsOfThisMonth?.FOUR_WHEELER?.COUNT_PERCENTAGE,
                            type: "percentage",
                            label: "",
                        },
                    ]}
                ></MetricWidget2>

                <MetricWidget2
                    xs={3}
                    metricIcon="layers_clear"
                    metricValueColor="warning.main"
                    metricIconColor="warning"
                    type="3"
                    fields={[
                        { label: "Two Wheeler", value: page.data.renewalsOfThisMonth?.TWO_WHEELER?.COUNT },
                        {
                            value: page.data.renewalsOfThisMonth?.TWO_WHEELER?.SUM,
                            label: "Total Premium Due",
                            type: "currency",
                        },
                        {
                            value: page.data.renewalsOfThisMonth?.TWO_WHEELER?.COUNT_PERCENTAGE,
                            type: "percentage",
                            label: "",
                        },
                    ]}
                ></MetricWidget2>
            </PMGrid>
 */}
            <PMGrid marginTop={4}>
                <PMText children="Upcoming Renewals (Next 7 Days)" variant="h6"></PMText>
            </PMGrid>

            <PMGrid container={true} marginTop={2}>
                <MetricWidget2
                    xs={3}
                    metricValueColor="primary"
                    metricIconColor="primary"
                    type="3"
                    fields={[
                        { label: "Commercial Vehicle", value: page.data.upcomingRenewalSevenDaysData?.CV?.COUNT||0 },
                        {
                            value: page.data.upcomingRenewalSevenDaysData?.CV?.SUM||0,
                            label: "Total Premium Due",
                            type: "currency",
                        },
                        {
                            value: page.data.upcomingRenewalSevenDaysData?.CV?.COUNT_PERCENTAGE||0,
                            type: "percentage",
                            label: "",
                        },
                    ]}
                ></MetricWidget2>

                <MetricWidget2
                    xs={3}
                    metricValueColor="success.main"
                    metricIconColor="success"
                    type="3"
                    fields={[
                        {
                            label: "Four Wheeler",
                            value: page.data.upcomingRenewalSevenDaysData?.FOUR_WHEELER?.COUNT||0,
                        },
                        {
                            value: page.data.upcomingRenewalSevenDaysData?.FOUR_WHEELER?.SUM||0,
                            label: "Total Premium Due",
                            type: "currency",
                        },
                        {
                            value: page.data.upcomingRenewalSevenDaysData?.FOUR_WHEELER?.COUNT_PERCENTAGE||0,
                            type: "percentage",
                            label: "",
                        },
                    ]}
                ></MetricWidget2>

                <MetricWidget2
                    xs={3}
                    metricValueColor="warning.main"
                    metricIconColor="warning"
                    type="3"
                    fields={[
                        {
                            label: "Two Wheeler",
                            value: page.data.upcomingRenewalSevenDaysData?.TWO_WHEELER?.COUNT||0,
                        },
                        {
                            value: page.data.upcomingRenewalSevenDaysData?.TWO_WHEELER?.SUM||0,
                            label: "Total Premium Due",
                            type: "currency",
                        },
                        {
                            value: page.data.upcomingRenewalSevenDaysData?.TWO_WHEELER?.COUNT_PERCENTAGE||0,
                            type: "percentage",
                            label: "",
                        },
                    ]}
                ></MetricWidget2>
            </PMGrid>
        </div>
    );
}
export default PolicyRenewalsDashboard;
