const policyRenewalsListFormatter = (data) => {

  var dataSet = JSON.parse(JSON.stringify(data));
  if (dataSet && dataSet["items"]) {
    dataSet["items"].forEach((element) => {
      element["customer_name"] = "Akash"
      element["total_premium"] = 0;
      element["insurer_name"] = element["insurer"]["name"];
    });
  }
  return dataSet;
};

  export default policyRenewalsListFormatter;
  