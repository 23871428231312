// @ts-nocheck

import { PMGrid, StatsCardWidget2, TableWidget } from "lib";
import { useState } from "react";

import { useDispatch } from "react-redux";
import { setStore } from "store/appSlice";

import { usePolicyOfThisMonthQuery } from "store/apiSlice";

import { usePolicyListQuery } from "store/apiSlice";
function PolicyList() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const dispatch = useDispatch();
    dispatch(setStore({ currentPageTitle: "" }));
    [page.parameters.policyListFilters, page.parameters.policyListFiltersSet] = useState({});
    [page.parameters.policyListPagination, page.parameters.policyListPaginationSet] = useState({ size: 50 });
    const policyOfThisMonthParams = {
        "group_by_values": "product_type",
        "agg_function": "SUM",
        "agg_on": "total_premium"
    };
    ({
        data: page.data.policyThisMonth,
        isFetching: page.data.policyThisMonthIsFetching,
        isLoading: page.data.policyThisMonthIsLoading,
    } = usePolicyOfThisMonthQuery(policyOfThisMonthParams));
    const policyListParams = {
        order_by: "-created_at",
        ...page.parameters.policyListFilters,
        ...page.parameters.policyListPagination,
    };
    ({
        data: page.data.policyListData,
        isFetching: page.data.policyListDataIsFetching,
        isLoading: page.data.policyListDataIsLoading,
    } = usePolicyListQuery(policyListParams));

    return (
        <div className="builder_wrapper">
            <PMGrid container={true}  marginTop={1} marginBottom={4}>
                    
                <StatsCardWidget2
                    xs={4}
                    statsIcon="airport_shuttle"
                    statsIconColor="primary"
                    type="4"
                    fields={[
                        { label: "Total Policy", value: ((page.data.policyThisMonth?.CV?.COUNT||0) + (page.data.policyThisMonth?.TWO_WHEELER?.COUNT || 0) + (page.data.policyThisMonth?.FOUR_WHEELER?.COUNT || 0)) || 0 },
                        { value: page.data.policyThisMonth?.CV?.COUNT || 0, label: "CV" },
                        { value: page.data.policyThisMonth?.FOUR_WHEELER?.COUNT || 0, label: "Four Wheeler" },
                        { value: page.data.policyThisMonth?.TWO_WHEELER?.COUNT || 0, label: "Two Wheeler" },
                    ]}
                ></StatsCardWidget2>

                <StatsCardWidget2
                    xs={4}
                    statsIcon=""
                    statsIconColor="warning"
                    type="4"
                    fields={[
                        { label: "Total Policy", value: ((page.data.policyThisMonth?.CV?.SUM || 0) + (page.data.policyThisMonth?.TWO_WHEELER?.SUM || 0) + (page.data.policyThisMonth?.FOUR_WHEELER?.SUM || 0)), type: "currency" },
                        { value: page.data.policyThisMonth?.CV?.SUM || 0, label: "CV", type: "currency" },
                        {
                            value: page.data.policyThisMonth?.FOUR_WHEELER?.SUM || 0,
                            label: "Four Wheeler",
                            type: "currency",
                        },
                        {
                            value: page.data.policyThisMonth?.TWO_WHEELER?.SUM || 0,
                            label: "Two Wheeler",
                            type: "currency",
                        },
                    ]}
                ></StatsCardWidget2>
            </PMGrid>

            <PMGrid container={true} xs={12}>
                <TableWidget
                    xs={12}
                    header="Insurance Policy List"
                    tableApi={page.data.policyListData || []}
                    tableParams={[page.parameters.policyListPagination, page.parameters.policyListPaginationSet]}
                    columns={[
                        { field: "policy_number", flex: 0.9, headerName: "Policy No" },
                        { field: "customer_name", headerName: "Customer Name" },
                        { field: "coverage_type", flex: 0.6, headerName: "Coverage Type" },
                        { field: "product_type", flex: 0.6, headerName: "Product Type" },
                        { field: "insurer.name", headerName: "Insurer", sortable: false },
                        { field: "total_premium", flex: 0.7, headerName: "Total Premium", type: "currency" },
                        { field: "start_date", flex: 0.6, headerName: "Start Date", type: "date" },
                        { field: "end_date", flex: 0.6, headerName: "End Date", type: "date" },
                        { field: "policy_document", flex: 0.5, headerName: "PDF", sortable: false, type: "download" },
                        {
                            field: "View",
                            headerName: "View",
                            type: "edit",
                            url: "/policy-edit",
                            flex: 0.6,
                            condition: [],
                        },
                    ]}
                ></TableWidget>
            </PMGrid>
        </div>
    );
}
export default PolicyList;
