// @ts-nocheck

import { useEffect, useState } from "react";
import { FormWidget } from "lib";
import { MenuWidget } from "./MenuWidget";

import { useEmployeeListAutocompleteQuery } from "store/apiSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "helperFn/errorSerializer";

import { useEmployeeCreateMutation } from "store/apiSlice";
import { default as employeeCreateFormatter } from "transformations/employeeCreateFormatter";
import { useNavigate } from "react-router-dom";
import { default as employeeListAutocompleteFormatter } from "transformations/employeeListAutocompleteFormatter";
import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";
function EmployeeCreate() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const [employeeListAutocompleteSkip, setemployeeListAutocompleteSkip] = useState(true);
    const [employeeListAutocompleteParams, setemployeeListAutocompleteParams] = useState({});
    const employeeListAutocompleteQuery = useEmployeeListAutocompleteQuery(employeeListAutocompleteParams, {
        skip: employeeListAutocompleteSkip,
    });
    const { enqueueSnackbar } = useSnackbar();
    const [employeeCreateMutationTrigger, employeeCreateMutationResult] = useEmployeeCreateMutation();
    const history = useNavigate();
    const [listOfEmployee, setlistOfEmployee] = useState([]);
    const [employeeCreateData, setemployeeCreateData] = useState([]);
    const dispatch = useDispatch();
    dispatch(setStore({ currentPageTitle: "" }));

    const leadNavigate = (data) => {
        history("/employee-list");
    };

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Employee Created Successfully.", { variant: "success" });
    };

    const employeeCreate = async (data) => {
        var apiData = employeeCreateFormatter(data);

        if (apiData) {
            employeeCreateMutationTrigger({
                name: apiData["name"],
                phone: apiData["phone"],
                email: apiData["email"],
                address: apiData["address"],
                city: apiData["city"],
                state: apiData["state"],
                pincode: apiData["pincode"],
                bank_account_name: apiData["bank_account_name"],
                bank_account_no: apiData["bank_account_no"],
                bank_ifsc_code: apiData["bank_ifsc_code"],
                bank_name: apiData["bank_name"],
                aadhar: apiData["aadhar"],
                pan: apiData["pan"],
                role: apiData["role"],
                department: apiData["department"],
                reporting_manager_id: apiData["reporting_manager_id"],
                status: apiData["status"],
            })
                .unwrap()
                .then((data) => {
                    showSuccessAlert(data);
                    leadNavigate(data);
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(errorSerializer(error), { variant: "error" });
                });
        }
    };

    const formSubmitApi = (data) => {
        employeeCreate(data);
    };

    const employeeCreateForm = (data) => {
        const responseData = data?.data || data;
        setemployeeCreateData(responseData);
    };

    const watchFormChange = (data) => {
        employeeCreateForm(data);
    };

    const employeeList = (data) => {
        const responseData = data?.data || data;
        setlistOfEmployee(employeeListAutocompleteFormatter(responseData));
    };

    const employeeListAutocompleteSuccess = (data) => {
        employeeList(data);
    };
    useEffect(() => {
        if (employeeListAutocompleteQuery.isSuccess) {
            employeeListAutocompleteSuccess(employeeListAutocompleteQuery);
        } else if (employeeListAutocompleteQuery.isError) {
            employeeListAutocompleteSuccess(null);
        }
    }, [employeeListAutocompleteQuery]);
    const employeeListAutocomplete = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setemployeeListAutocompleteSkip(false);
    };

    useEffect(() => {
        employeeListAutocomplete();
    }, []);

    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "Create Employee" }}>
                <FormWidget
                    sx={{ my: 2 }}
                    header="Create Employee"
                    submitButtonLabel="Create Employee"
                    Onchange={watchFormChange}
                    Onsubmit={formSubmitApi}
                    fieldsets={[
                        {
                            direction: "row",
                            fields: [
                                { label: "Employee Name", name: "name", type: "text", required: true },
                                {
                                    label: "Phone",
                                    name: "phone",
                                    type: "phone",
                                    required: true,
                                    validation: { minLength: 10, maxLength: 10, pattern: "^\\d+$" },
                                },
                                { label: "Email", name: "email", type: "email", required: true },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Address Details",
                            fields: [
                                { label: "Address", name: "address", type: "text" },
                                { label: "City", name: "city", type: "text" },
                                { label: "State", name: "state", type: "text" },
                                {
                                    label: "Pincode",
                                    name: "pincode",
                                    type: "text",
                                    validation: { minLength: 6, maxLength: 6, pattern: "^\\d+$" },
                                },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Account Details",
                            fields: [
                                { label: "Bank Name", name: "bank_name", type: "text" },
                                {
                                    label: "A/C No.",
                                    name: "bank_account_name",
                                    type: "text",
                                    validation: { minLength: 10, maxLength: 15, pattern: "^\\d+$" },
                                },
                                {
                                    label: "IFSC Code",
                                    name: "bank_ifsc_code",
                                    type: "text",
                                    validation: {
                                        minLength: 11,
                                        maxLength: 11,
                                        pattern: "^[A-Za-z]{4}[0]{1}[0-9]{6}$",
                                    },
                                },
                                {
                                    label: "Aadhaar No",
                                    name: "aadhar",
                                    type: "text",
                                    validation: { minLength: 12, maxLength: 12, pattern: "^[1-9]{1}[0-9]{11}$" },
                                },
                                {
                                    label: "Pan No",
                                    name: "pan",
                                    type: "text",
                                    validation: {
                                        minLength: 10,
                                        maxLength: 10,
                                        pattern: "^[A-Za-z]{3}[CPHFATBLJGcphfatbljg]{1}[A-Za-z]{1}[0-9]{4}[A-Za-z]{1}$",
                                    },
                                },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Department Details",
                            fields: [
                                {
                                    label: "Role",
                                    name: "role",
                                    type: "select",
                                    options: [
                                        { id: "CXO", label: "CXO" },
                                        { id: "RM", label: "RM" },
                                    ],
                                    required: true,
                                },
                                {
                                    label: "Department",
                                    name: "department",
                                    type: "select",
                                    options: [
                                        { id: "OPERATIONS", label: "Operations" },
                                        { id: "SALES", label: "Sales" },
                                        { id: "BUSINESS", label: "Business" },
                                    ],
                                    required: true,
                                },
                                {
                                    label: "Reporting Manager",
                                    name: "manager",
                                    type: "select",
                                    options: listOfEmployee,
                                    required: true,
                                },
                                { label: "Is Active", name: "status", type: "checkbox" },
                            ],
                        },
                    ]}
                ></FormWidget>
            </MenuWidget>
        </div>
    );
}
export default EmployeeCreate;
