// @ts-nocheck

import { FormWidget, Image, PMCard, PMGrid } from "lib";

import { errorSerializer } from "helperFn/errorSerializer";
import { useSnackbar } from "notistack";

import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useResetPasswordMutation } from "store/apiSlice";
import { setStore } from "store/appSlice";
import { default as resetPasswordFormatter } from "transformations/resetPasswordFormatter";
function ResetPassword() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const { enqueueSnackbar } = useSnackbar();
    const [resetPasswordMutationTrigger, resetPasswordMutationResult] = useResetPasswordMutation();
    const history = useNavigate();
    ({ id: page.parameters.route.id } = useParams());
    const dispatch = useDispatch();
    dispatch(setStore({ currentPageTitle: "" }));

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Password Changed", { variant: "success" });
    };

    const pocreated = (data) => {
        history("/login/");
    };

    const resetPassword = async (data) => {
        var apiData = resetPasswordFormatter(data, id, enqueueSnackbar);

        if (apiData && apiData["password_check"]) {
            resetPasswordMutationTrigger({
                password: apiData["password"],
                domain: apiData["domain"],
                token: apiData["token"],
            })
                .unwrap()
                .then((data) => {
                    pocreated(data);
                    showSuccessAlert(data);
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(errorSerializer(error), { variant: "error" });
                });
        }
    };

    const loginForm = (data) => {
        resetPassword(data);
    };

    return (
        <div className="builder_wrapper">
            <PMGrid
                container={true}
                background="#007493"
                sx={{ direction: "column", alignItems: "center", justifyContent: "center" }}
                item={true}
                minHeight="100vh"
            >
                <PMGrid item={true} container={true} md={3} direction="column" background="#FFFFFF">
                    <PMCard raised={true}>
                        <PMGrid container={true} rowSpacing={3} alignSelf="center">
                            <PMGrid
                                item={true}
                                xs={12}
                                container={true}
                                sx={{ "margin-top": 10 }}
                                justifyContent="center"
                            >
                                <Image image="https://www.permutehq.com/assets/images/logo/logo.svg" md={6}></Image>
                            </PMGrid>

                            <PMGrid item={true} xs={12} justifyContent="center">
                                <FormWidget
                                    direction="column"
                                    header="Reset Password"
                                    Onsubmit={loginForm}
                                    fieldsets={[
                                        {
                                            direction: "column",
                                            fields: [
                                                {
                                                    label: "Password",
                                                    name: "password",
                                                    type: "password",
                                                    required: true,
                                                    fullWidth: true,
                                                },
                                                {
                                                    label: "Re-Enter Password",
                                                    name: "repassword",
                                                    type: "password",
                                                    required: true,
                                                    fullWidth: true,
                                                },
                                            ],
                                        },
                                    ]}
                                ></FormWidget>
                            </PMGrid>
                        </PMGrid>
                    </PMCard>
                </PMGrid>
            </PMGrid>
        </div>
    );
}
export default ResetPassword;
