const renewalDetailsFormatter = (data,  renewal_data, dataKey) => {
  console.log(renewal_data, "renewal-data", data)
  const policies = JSON.parse(JSON.stringify(data||data));
  const renewals = JSON.parse(JSON.stringify(renewal_data||renewal_data));
  console.log(renewals, data)
  renewals?.items?.forEach(element => {
    policies?.items?.forEach(item => {
      if (element.existing_policy_number === item.policy_no){
        element["total_premium"] = item?.total_premium
        element["customer_name"] = item?.customer_name
        element["posp_code"] = item?.posp?.posp_code
        element["insurer_name"] = element?.insurer?.name
        element["url"] = "posp-code-renewals"
        console.log(item?.posp?.code, item?.insurer?.name)
      }
    });
  });
  const dataSet = {}
  if (dataKey){
    dataSet[dataKey] = renewals
    // return dataSet;
    return dataSet

  }
  else{
    return renewals
  }
};

  export default renewalDetailsFormatter;
  