// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, TableWidget } from "lib";
import { MenuWidget } from "./MenuWidget";

import { usePolicyListQuery } from "store/apiSlice";

import { useRmRenewalsDoneListQuery } from "store/apiSlice";
import { useDispatch } from "react-redux";
import { setStore } from "store/appSlice";
import { default as rmRenewalsDoneListFormatter } from "transformations/rmRenewalsDoneListFormatter";
import { default as policyListFormatter } from "transformations/policyListFormatter";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
function RMRenewalsList() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const [policyListSkip, setpolicyListSkip] = useState(true);
    const [policyListParams, setpolicyListParams] = useState({});
    const policyListQuery = usePolicyListQuery(policyListParams, { skip: policyListSkip });
    const [rmRenewalsDoneListSkip, setrmRenewalsDoneListSkip] = useState(true);
    const [rmRenewalsDoneListParams, setrmRenewalsDoneListParams] = useState({});
    const rmRenewalsDoneListQuery = useRmRenewalsDoneListQuery(rmRenewalsDoneListParams, {
        skip: rmRenewalsDoneListSkip,
    });
    const dispatch = useDispatch();
    const [rmRenewalsData, setrmRenewalsData] = useState([]);
    const rmRenewalsDoneListData = useSelector((states) => states?.appStore?.rmRenewalsDoneListData);
    ({ id: page.parameters.route.id } = useParams());
    dispatch(setStore({ currentPageTitle: "" }));

    const policyListData = (data) => {
        const responseData = data?.data || data;
        setrmRenewalsData(policyListFormatter(responseData, rmRenewalsDoneListData));
    };

    const policyListSuccess = (data) => {
        policyListData(data);
    };
    useEffect(() => {
        if (policyListQuery.isSuccess) {
            policyListSuccess(policyListQuery);
        } else if (policyListQuery.isError) {
            policyListSuccess(null);
        }
    }, [policyListQuery]);
    const policyList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setpolicyListSkip(false);
    };

    const rmRenewalsDoneListListData = (data) => {
        var formatedData = {
            ...rmRenewalsDoneListFormatter(data?.data || data, "rmRenewalsDoneListData"),
        };
        dispatch(setStore(formatedData));
    };

    const rmRenewalsDoneListSuccess = (data) => {
        rmRenewalsDoneListListData(data);
        policyList(data);
    };
    useEffect(() => {
        if (rmRenewalsDoneListQuery.isSuccess) {
            rmRenewalsDoneListSuccess(rmRenewalsDoneListQuery);
        } else if (rmRenewalsDoneListQuery.isError) {
            rmRenewalsDoneListSuccess(null);
        }
    }, [rmRenewalsDoneListQuery]);
    const rmRenewalsDoneList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setrmRenewalsDoneListParams({ rm_id: id });
        setrmRenewalsDoneListSkip(false);
    };

    useEffect(() => {
        rmRenewalsDoneList();
    }, [id]);

    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "RM Renewals List" }}>
                <PMGrid container={true} marginTop={2}>
                    <TableWidget
                        header="RM Wise Renewals Data"
                        showSerialNumber={false}
                        showQuickFilter={false}
                        hideFooter={true}
                        xs={12}
                        rows={{ name: "rmRenewalsData" }}
                        columns={[
                            { field: "due_date", headerName: "Due Date", type: "date" },
                            { field: "posp_code", headerName: "POSP Code", type: "relatedlink" },
                            { field: "existing_policy_number", headerName: "Policy Number" },
                            { field: "product_type", headerName: "Product Type" },
                            { field: "insurer_name", headerName: "Insurer" },
                            { field: "customer_name", headerName: "Customer Name" },
                            { field: "total_premium", headerName: "Total Premium", type: "currency" },
                            {
                                field: "status",
                                headerName: "Renewal Status",
                                type: "chip",
                                condition: [
                                    { rule: "MISSED", color: "error" },
                                    { rule: "PENDING", color: "warning" },
                                    { rule: "DONE", color: "success" },
                                    { rule: "FAILED", color: "secondary" },
                                ],
                            },
                            { field: "insurer_renewal_notice", headerName: "Insurer RN", type: "download" },
                            { field: "broker_renewal_notice", headerName: "MY RN", type: "download" },
                            { field: "view", headerName: "View", type: "edit", url: "/renewal" },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default RMRenewalsList;
