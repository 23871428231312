const transformPolicyMissedStatsCard = (data) => {
  const dataSet = JSON.parse(JSON.stringify(data?.items||data));
  const dataset = {'intervalMissed':[], 'productWiseMissed':[], 'intervalFailed':[], 'inGracePeriod':[]}
//   console.log(dataSet, '==============================')
  dataset.intervalMissed.push({'label':'Total Renewals Missed','value':dataSet['total_missed_renewal']})
  dataset.intervalMissed.push({'label':'In Last 7 Days','value':dataSet['total_missed_renewal_7_days']})
  dataset.intervalMissed.push({'label':'In Last 30 Days','value':dataSet['total_missed_renewal_30_days']})
  dataset.intervalFailed.push({'label':'Total Lost','value':dataSet['total_failed_renewal']})
  dataset.intervalFailed.push({'label':'In Last 7 Days','value':dataSet['total_failed_renewal_7_days']})
  dataset.intervalFailed.push({'label':'In Last 30 Days','value':dataSet['total_failed_renewal_30_days']})
  dataset.productWiseMissed.push({'label':'Commercial Vehicle','value':(dataSet['total_missed_renewal_cv']||0)})
  dataset.productWiseMissed.push({'label':'Four Wheeler','value':dataSet['total_missed_renewal_four_wheeler']})
  dataset.productWiseMissed.push({'label':'Two Wheeler','value':dataSet['total_missed_renewal_two_wheeler']})
  dataset.inGracePeriod.push({'label':'In Grace Period','value':dataSet['total_grace_period_renewal_15_days']})
  dataset.inGracePeriod.push({'label':'Expiring in 7 days','value':dataSet['total_grace_period_renewal_7_days']})
  dataset.inGracePeriod.push({'label':'Expiring in 15 Days','value':dataSet['total_grace_period_renewal_15_days']})
  return dataset;
};

  export default transformPolicyMissedStatsCard;
  