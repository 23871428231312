import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import { PMGrid, PMText } from "../../pmcomponents";
import { BaseWidget, BaseWidgetProps } from "../BaseWidget";

export interface NotesWidgetProps {
    text?: string,
    textColor?: string,
    icon?: string
    data: Object
    fields: Array<any>
    columns?: number
    columnSpacing?: number
    rowSpacing?: number
}

const locale = 'en-IN'
const currencyFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'INR',
});

/* Example Field data*/
// const fields = [
//     {label: "First Name", key: "first_name" },
//     {label: "Last Name", key: "last_name"},
//     {label: "Phone", key: "phone"},
//     {label: "Email", key: "email"}
// ]

export const NotesWidget = (props: BaseWidgetProps & NotesWidgetProps) => {
    const {columns, columnSpacing, rowSpacing, ...baseProps} = props;

    return (
        <BaseWidget {...baseProps}>
                <Box sx={{
                        display: 'grid',
                        columnGap: columnSpacing,
                        rowGap: rowSpacing,
                        gridTemplateColumns: `repeat(${columns}, 1fr)`,
                    }}
                    >
                    {
                        props.fields.map((field, index) => {
                            return (
                                <PMGrid key={index}>
                                    <Stack>
                                        <PMText variant="h6">{field.label}</PMText>
                                        
                                    </Stack>
                                </PMGrid>)
                        })
                    }
                </Box>
        </BaseWidget>
    )
}

NotesWidget.defaultProps = {
    columns: 4,
    columnSpacing: 1,
    rowSpacing: 3,
}

export default NotesWidget