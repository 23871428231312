// @ts-nocheck

import { useEffect, useState } from "react";
import { PMCard, PMGrid, PMText, PMButton, DetailWidget, FormWidget, TableWidget } from "lib";
import { MenuWidget } from "./MenuWidget";
import { useSelector } from "react-redux";

import { useRenewalPolicyDetailQuery } from "store/apiSlice";

import { useRenewalInteractionHistoryQuery } from "store/apiSlice";

import { useRenewalDetailFollowupQuery } from "store/apiSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "helperFn/errorSerializer";

import { useInteractionCreateMutation } from "store/apiSlice";
import { jsonToFormData } from "helperFn/formData";
import { default as interactionCreateFormatter } from "transformations/interactionCreateFormatter";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setStore } from "store/appSlice";
import { default as renewalDetailFollowupFormatter } from "transformations/renewalDetailFollowupFormatter";
import { default as renewalInteractionHistoryFormatter } from "transformations/renewalInteractionHistoryFormatter";
import { default as renewalPolicyDetailsFormatter } from "transformations/renewalPolicyDetailsFormatter";
import { useParams } from "react-router-dom";
function FollowupDetail() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const renewalDetailFollowupData = useSelector((states) => states?.appStore?.renewalDetailFollowupData);
    const renewalInteractionHistoryData = useSelector((states) => states?.appStore?.renewalInteractionHistoryData);
    const [renewalPolicyDetailSkip, setrenewalPolicyDetailSkip] = useState(true);
    const [renewalPolicyDetailParams, setrenewalPolicyDetailParams] = useState({});
    const renewalPolicyDetailQuery = useRenewalPolicyDetailQuery(renewalPolicyDetailParams, {
        skip: renewalPolicyDetailSkip,
    });
    const [renewalInteractionHistorySkip, setrenewalInteractionHistorySkip] = useState(true);
    const [renewalInteractionHistoryParams, setrenewalInteractionHistoryParams] = useState({});
    const renewalInteractionHistoryQuery = useRenewalInteractionHistoryQuery(renewalInteractionHistoryParams, {
        skip: renewalInteractionHistorySkip,
    });
    const [renewalDetailFollowupSkip, setrenewalDetailFollowupSkip] = useState(true);
    const [renewalDetailFollowupParams, setrenewalDetailFollowupParams] = useState({});
    const renewalDetailFollowupQuery = useRenewalDetailFollowupQuery(renewalDetailFollowupParams, {
        skip: renewalDetailFollowupSkip,
    });
    const { enqueueSnackbar } = useSnackbar();
    const [interactionCreateMutationTrigger, interactionCreateMutationResult] = useInteractionCreateMutation();
    const history = useNavigate();
    const dispatch = useDispatch();
    const [undefined, setundefined] = useState([]);
    const renewalPolicyDetailData = useSelector((states) => states?.appStore?.renewalPolicyDetailData);
    ({ id: page.parameters.route.id } = useParams());
    dispatch(setStore({ currentPageTitle: "" }));

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Interaction Created", { variant: "success" });
    };

    const interactionCreate = async (data) => {
        var apiData = interactionCreateFormatter(data);

        if (apiData) {
            interactionCreateMutationTrigger(
                jsonToFormData({
                    content: apiData["content"],
                    interaction_with: apiData["interaction_with"],
                    interaction_status: apiData["interaction_status"],
                    target: apiData["target"],
                    entity_type: apiData["entity_type"],
                    entity_id: apiData["entity_id"],
                    receiver: apiData["receiver"],
                    agent_id: apiData["agent_id"],
                    subject: apiData["subject"],
                    actual_time: apiData["actual_time"],
                    type: apiData["type"],
                    files: apiData["files"],
                })
            )
                .unwrap()
                .then((data) => {
                    refetchRenewalInteractionHistorySkip(data);
                    showSuccessAlert(data);
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(errorSerializer(error), { variant: "error" });
                });
        }
    };

    const interactionSubmitApi = (data) => {
        interactionCreate(data);
    };

    const MenuHomeNavigate = (data) => {
        history("/leads/disposition" + "/" + id);
    };

    const callEndfn = (data) => {
        MenuHomeNavigate(data);
    };

    const renewalInteractionHistoryListData = (data) => {
        var formatedData = {
            ...renewalInteractionHistoryFormatter(data?.data || data, "renewalInteractionHistoryData"),
        };
        dispatch(setStore(formatedData));
    };

    const renewalInteractionHistorySuccess = (data) => {
        renewalInteractionHistoryListData(data);
    };
    useEffect(() => {
        if (renewalInteractionHistoryQuery.isSuccess) {
            renewalInteractionHistorySuccess(renewalInteractionHistoryQuery);
        } else if (renewalInteractionHistoryQuery.isError) {
            renewalInteractionHistorySuccess(null);
        }
    }, [renewalInteractionHistoryQuery]);
    const renewalInteractionHistory = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setrenewalInteractionHistoryParams({
            entity_id: renewalDetailFollowupData,
            entity_type: data ? data["RENEWAL"] : "RENEWAL",
        });
        setrenewalInteractionHistorySkip(false);
    };

    const renewalPolicyDetailListData = (data) => {
        const responseData = data?.data || data;
        setundefined(renewalPolicyDetailsFormatter(responseData));
    };

    const renewalPolicyDetailSuccess = (data) => {
        renewalPolicyDetailListData(data);
    };
    useEffect(() => {
        if (renewalPolicyDetailQuery.isSuccess) {
            renewalPolicyDetailSuccess(renewalPolicyDetailQuery);
        } else if (renewalPolicyDetailQuery.isError) {
            renewalPolicyDetailSuccess(null);
        }
    }, [renewalPolicyDetailQuery]);
    const renewalPolicyDetail = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setrenewalPolicyDetailParams({
            policy_no: renewalDetailFollowupData?.existing_policy_number,
            entity_type: data ? data["RENEWAL"] : "RENEWAL",
        });
        setrenewalPolicyDetailSkip(false);
    };

    const renewalDetailFollowupListData = (data) => {
        var formatedData = {
            ...renewalDetailFollowupFormatter(data?.data || data, "renewalDetailFollowupData"),
        };
        dispatch(setStore(formatedData));
    };

    const renewalDetailFollowupSuccess = (data) => {
        renewalDetailFollowupListData(data);
        renewalPolicyDetailApi(data);
        renewalInteractionHistoryApi(data);
    };
    useEffect(() => {
        if (renewalDetailFollowupQuery.isSuccess) {
            renewalDetailFollowupSuccess(renewalDetailFollowupQuery);
        } else if (renewalDetailFollowupQuery.isError) {
            renewalDetailFollowupSuccess(null);
        }
    }, [renewalDetailFollowupQuery]);
    const renewalDetailFollowup = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setrenewalDetailFollowupParams({ id: id });
        setrenewalDetailFollowupSkip(false);
    };

    useEffect(() => {
        renewalDetailFollowup();
    }, [id, renewalDetailFollowupData?.existing_policy_number, renewalDetailFollowupData]);

    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "Interaction Page" }}>
                <PMCard sx={{ minWidth: "100%", backgroundColor: "primary.light" }} direction="row">
                    <PMGrid container={true} md={12} direction="row">
                        <PMGrid md="6" direction="column">
                            <PMText children="Call Duration" variant="h6"></PMText>

                            <PMText children="10: 40" variant="h4"></PMText>
                        </PMGrid>

                        <PMGrid md="2" sx={{ alignSelf: "center" }}>
                            <PMButton label="End Call" variant="contained" color="error" onClick={callEndfn}></PMButton>
                        </PMGrid>

                        <PMGrid md="2" sx={{ alignSelf: "center" }} direction="column">
                            <PMButton label="Transfer Call" variant="contained" color="info"></PMButton>
                        </PMGrid>

                        <PMGrid sx={{ alignSelf: "center" }} md="2" direction="column">
                            <PMButton label="Hold Call" variant="contained" color="warning"></PMButton>
                        </PMGrid>
                    </PMGrid>
                </PMCard>

                <PMGrid container={true} md={12} direction="row">
                    <PMGrid container={true} md={6} sx={{ my: 1 }} direction="row">
                        <DetailWidget
                            direction="row"
                            header="POSP Summary"
                            md={12}
                            fields={[
                                { label: "POSP Name", value: renewalDetailFollowupData?.posp?.name },
                                { label: "POSP Code", value: renewalDetailFollowupData?.posp?.posp_code },
                                { label: "POSP Email", value: renewalDetailFollowupData?.posp?.posp_email },
                                { label: "RM", value: renewalDetailFollowupData?.rm?.name },
                            ]}
                        ></DetailWidget>

                        <DetailWidget
                            direction="row"
                            header="Renewal Details"
                            md={12}
                            fields={[
                                { label: "Policy No", value: renewalDetailFollowupData?.existing_policy_number },
                                { label: "Product Type", value: renewalDetailFollowupData?.product_type },
                                { label: "Coverage Type", value: renewalDetailFollowupData?.coverage_type },
                                { label: "Due Date", value: renewalDetailFollowupData?.due_date },
                                { label: "Insurer", value: renewalDetailFollowupData?.insurer?.name },
                            ]}
                        ></DetailWidget>

                        <DetailWidget
                            header="Premium Details"
                            direction="row"
                            md={12}
                            fields={[
                                { label: "OD Premium", value: renewalPolicyDetailData?.total_od_premium },
                                { label: "TP Premium", value: renewalPolicyDetailData?.total_tp_premium },
                                { label: "Total Premium", value: renewalPolicyDetailData?.total_premium },
                                { label: "IDV", value: renewalPolicyDetailData?.sum_insured },
                                { label: "NCB", value: renewalPolicyDetailData?.ncb },
                            ]}
                        ></DetailWidget>

                        <FormWidget
                            header="Interaction with POSP"
                            Onsubmit={interactionSubmitApi}
                            fieldsets={[
                                {
                                    direction: "row",
                                    grid: 12,
                                    fields: [
                                        {
                                            label: "Mode",
                                            name: "mode",
                                            type: "select",
                                            required: true,
                                            options: [
                                                { id: "EMAIL", label: "Email" },
                                                { id: "wHATSAPP", label: "WhatsApp" },
                                                { id: "SMS", label: "SMS" },
                                            ],
                                        },
                                        { label: "Message", name: "message", type: "textarea", required: true },
                                        { label: "Attach Document", name: "document", type: "file" },
                                    ],
                                },
                            ]}
                        ></FormWidget>
                    </PMGrid>

                    <PMGrid container={true} md={6} sx={{ my: 1 }} direction="row">
                        <DetailWidget
                            md={12}
                            fields={[
                                {
                                    label: "Script",
                                    value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                                },
                            ]}
                        ></DetailWidget>

                        <TableWidget
                            header="Previous Calling History"
                            showQuickFilter={false}
                            xs={12}
                            rows={[
                                { date: "2023-01-02", status: "Renewal Due", remark: "Followup Scheduled" },
                                { date: "2023-01-04", status: "Renewal Due", remark: "Followup Scheduled" },
                            ]}
                            columns={[
                                { field: "date", headerName: "Date", type: "date" },
                                { field: "status", headerName: "Status" },
                                { field: "remark", headerName: "Remark", flex: 2 },
                            ]}
                        ></TableWidget>

                        <TableWidget
                            header="Past Interaction History"
                            showQuickFilter={false}
                            pageSize={6}
                            xs={12}
                            rows={renewalInteractionHistoryData || []}
                            columns={[
                                { field: "created_at", headerName: "Date", type: "date" },
                                { field: "type", headerName: "Activity" },
                                { field: "content", headerName: "Comment", flex: 2 },
                            ]}
                        ></TableWidget>
                    </PMGrid>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default FollowupDetail;
