// @ts-nocheck

import { useEffect, useState } from "react";
import { PMGrid, TableWidget } from "lib";

import { setStore } from "store/appSlice";
import { useDispatch } from "react-redux";

import { useFollowupListQuery } from "store/apiSlice";
function MyActivityFollowup() {
    // Hold page variables to avoid polluting variable space
    var page = {
        data: {},
        parameters: {
            route: {},
        },
    };

    const dispatch = useDispatch();
    dispatch(setStore({ currentPageTitle: "" }));
    const followupListParams = {};
    ({
        data: page.data.followupListData,
        isFetching: page.data.followupListDataIsFetching,
        isLoading: page.data.followupListDataIsLoading,
    } = useFollowupListQuery(followupListParams));

    return (
        <div className="builder_wrapper">
            <PMGrid container={true} marginTop={2}>
                <TableWidget
                    header="FollowUp List"
                    showSerialNumber={false}
                    xs={12}
                    rows={page.data.followupListData}
                    columns={[
                        { field: "posp_name", headerName: "POSP Name" },
                        { field: "posp_code", headerName: "POSP Code" },
                        { field: "existing_policy_number", headerName: "Policy No." },
                        { field: "due_date", headerName: "Due Date", type: "date" },
                        { field: "customer_name", headerName: "Customer Name", sortable: false },
                        { field: "product_type", headerName: "Product Type" },
                        { field: "insurer", headerName: "Insurer" },
                        {
                            field: "status",
                            headerName: "Renewal Status",
                            sortable: false,
                            type: "chip",
                            condition: [
                                { rule: "MISSED", color: "error" },
                                { rule: "PENDING", color: "warning" },
                                { rule: "DONE", color: "success" },
                                { rule: "FAILED", color: "secondary" },
                            ],
                        },
                        { field: "Call", headerName: "View Details", type: "edit", url: "/my-followup" },
                    ]}
                ></TableWidget>
            </PMGrid>
        </div>
    );
}
export default MyActivityFollowup;
